/** @format */

@media print {
  .lavender-container {
    display: none !important;
  }
  .nH {
    width: 100vw;
  }
}

/*outreach outbox fix*/
body > div > .dialog-component {
  z-index: 500;
}

/*outlook reply fix*/
.O4Jj0 {
  position: relative;
}

.lavender-container {
  width: 300px;
  background-color: white;
  font-weight: 300;
  font-family: Nunito, sans-serif;
  /*letter-spacing: 1px;*/
}
.lavender-container .profile-pic {
  width: 110px;
  height: 110px;
  margin: 0 8px 8px 8px;
  display: inline-block;
  vertical-align: middle;

  border-bottom: 8px solid #9370db;
  object-fit: cover;
}
.lavender-container h2 {
  margin: 0;
  padding: 16px 0 16px 8px;
  font-size: 1.2em;
  vertical-align: middle;
  font-weight: 300;
}
.lavender-container .header {
  font-size: 15px;
  overflow: auto;
}
.lavender-container .header table td {
  vertical-align: top;
}
.lavender-profile-img-tag {
  width: 90%;
  height: 90%;
  border-radius: 100%;
}
.lavender-container .title {
  text-transform: uppercase;
  line-height: 36px;
  vertical-align: center;
  margin: 0;
  padding-left: 8px;
  height: 36px;
  width: 292px;
  font-size: 0.9em;
  background-color: #9370db;
  font-family: montserrat, sans-serif;
  letter-spacing: 3px;
  color: white;
  font-weight: 300;
}

.lavender-container .header h3 {
  padding: 2px 8px 2px 0;
  width: 148px;
  vertical-align: middle;
  margin: 0 0 6px 0;
  font-weight: 300;
}
.lavender-container .header h4 {
  padding: 2px 8px 2px 0;
  width: 148px;
  vertical-align: middle;
  margin: 0 0 6px 0;
  font-size: 0.7em;
  font-weight: 300;
}

.lavender-container .header h3 .company {
  color: #9370db;
  font-weight: 400;
}
.lavender-container h3 {
  padding: 8px 8px 0 8px;
  margin: 0;
  font-size: 0.8em;
  font-weight: 300;
}
.lavender-container h4 {
  padding: 0 8px 0 8px;
  margin: 0;
  font-size: 0.7em;
  font-weight: 300;
}
.lavender-container .social-media-box a {
  background-image: url("https://sorter.s3.amazonaws.com/socials/site.png");
  background-size: contain;
}
.lavender-container .social-media-box .linkedin {
  background-image: url("https://sorter.s3.amazonaws.com/socials/linkedin.png");
}
.lavender-container .social-media-box .facebook {
  background-image: url("https://sorter.s3.amazonaws.com/socials/facebook.png");
}
.lavender-container .social-media-box .twitter {
  background-image: url("https://sorter.s3.amazonaws.com/socials/twitter.png");
}
.lavender-container .social-media-box .youtube {
  background-image: url("https://app.sorter.com/public/img/lavender/youtube.png");
}
.lavender-container .social-media-box .instagram {
  background-image: url("https://app.sorter.com/public/img/lavender/youtube.png");
}
.lavender-container .social-media-box .pinterest {
  background-image: url("https://app.sorter.com/public/img/lavender/pinterest.png");
}
.lavender-container .social-media-box {
  margin-top: 16px;
}
.lavender-container .social-media-box a {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 8px 0 8px 8px;
  background-size: contain;
}
.lavender-container .not-found {
  color: gray;
  text-align: center;
  margin-top: 144px;
  margin-bottom: 200px;
}
.lavender-container .bio {
  margin: 8px;
  font-size: 0.7em;
  line-height: 18px;
  font-weight: 300;
  text-transform: none;
}

.lavender-container .welcome-container {
  width: calc(100% - 32px);
  height: 468px;
  padding: 16px;
  text-align: center;
}
.lavender-container .welcome-container h1 {
  color: #9370db;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 10px;
  margin: 0;
  font-size: 2em;
}
.lavender-container .welcome-container h2 {
  margin-top: 100px;
  font-weight: 400;
}
.lavender-container .welcome-container p {
  margin-top: 12px;
  padding: 16px;
  font-weight: normal;
}
.lavender-container .welcome-container .gmail img {
  width: 160px;
  height: 32px;
  cursor: pointer;
}
.lavender-container .twitter-header {
  border-top: 4px solid #9370db;
  padding-top: 4px;
  padding-bottom: 4px;
}
.lavender-container.twitter-header {
  border-top: 4px solid gray;
}
.lavender-container .twitter-header img {
  height: 30px;
  width: 30px;
  display: inline-block;
  margin-left: 4px;
  vertical-align: middle;
  line-height: 30px;
}
.lavender-container .twitter-header p {
  display: inline-block;
  width: fit-content;
  vertical-align: middle;
  padding: 0;
  height: 30px;
  line-height: 30px;
  margin: 0 0 0 8px;
  text-transform: none;
  color: black;
}
.lavender-container.lv-dark-mode .twitter-header p {
  color: lightgray;
}
.lavender-container .tweet-box {
  margin: 8px;
}
.lavender-container .tweet-box p {
  margin: 4px;
}

.lavender-container .tweet-box .twitter-date {
  color: gray;
  font-size: 10px;
  vertical-align: top;
  margin-right: 8px;
}
.lavender-container .tweet-box .twitter-tweet {
  color: black;
  font-size: 11px;
  vertical-align: top;
  text-transform: none;
}
.lavender-container.lv-dark-mode .twitter-tweet {
  color: lightgray;
}
.lavender-container .tweet-box .twitter-tweet span {
  color: #9370db;
  font-weight: 400;
}
.lavender-container .lavender-option-container iframe {
  border: 0;
  height: calc(100vh - 72px);
  width: 300px;
  background: url("https://sorter.s3.amazonaws.com/lavender/images/page+loading.gif")
    no-repeat 100px 50%;
  background-size: 100px 100px;
  display: block;
}

.lavender-container {
  position: fixed;
  z-index: 10000;
  right: 0;
  top: 0;
  height: 100vh;
  width: 300px;
  background-color: white;
  font-family: Nunito, sans-serif;
  box-shadow: 0 0 50px rgba(45, 62, 80, 0.2);
  /*border-left: 4px solid #524ddb;*/
  color: black;
}

.lavender-container p {
  color: black;
}

.lavender-container.lv-dark-mode p {
  color: lightgray;
}

.lavender-container.lv-dark-mode .dm-img {
  filter: invert(1);
}

.lavender-container.lv-dark-mode {
  background-color: #121212; /*2a292b*/
  color: lightgray;
}

.lavender-container a,
.lavender-container a:visited,
.lavender-container a:hover,
.lavender-container a:active {
  color: inherit;
}

.lavender-container .lavender-menu {
  width: 100%;
  vertical-align: top;
  height: 36px;
  background-color: #9370db;
  background-image: linear-gradient(to right, #524ddb -2%, #9370db 103%);
  color: white;
  text-align: center;
  position: absolute;
  bottom: 0;
}

.lavender-container.lv-dark-mode .lavender-menu {
  /*background: #9370db;*/
  text-align: center;
}

.lavender-container .lavender-menu div {
  height: 36px;
  width: 35px;
  line-height: 36px;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  font-weight: 100;
  margin-bottom: 8px;
  display: inline-block;
  margin-left: 16px;
  margin-right: 16px;
}

.lavender-container .lavender-menu div img,
.lavender-container .lavender-menu div svg {
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-top: 8px;
  vertical-align: initial;
  fill: white;
}
.lavender-container.lv-dark-mode {
  background: #39353b;
}

.lavender-container .lavender-right-container {
  width: 300px;
  display: inline-block;
}

.lavender-container .lavender-option-container {
  height: calc(100vh - 72px);
  overflow-x: hidden;
  overflow-y: overlay;
}

.lavender-container .lavender-menu {
  display: inline-block;
}

.lavender-container .lavender-title {
  text-transform: uppercase;
  line-height: 36px;
  vertical-align: middle;
  margin: 0;
  padding-left: 8px;
  height: 36px;
  font-size: 14px;
  background-image: linear-gradient(to right, #524ddb -2%, #9370db 103%);
  font-family: Roboto, sans-serif;
  letter-spacing: 3px;
  color: white;
  font-weight: 300;
  position: relative;
}

.lavender-everywhere-header .lv-close,
.lavender-container .lavender-title .lv-close {
  position: absolute;
  right: 0;
  top: 0;
  width: 36px;
  height: 36px;
  font-size: 16px;
  text-align: center;
  vertical-align: center;
  line-height: 36px;
  background: url("https://app.trylavender.com/public/img/gifs/gif-close-icon.png"); /*TODO: MAKE SURE THIS IS GOING TO WORK ON PRODUCTION, changed path from img/gif-close-icon.png to img/gifs/gif-close-icon.png*/
  padding-top: 2px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px 12px;
  cursor: pointer;
}

.lavender-container.lv-dark-mode .lavender-title .lv-close {
  color: white;
}

.lavender-container .lavender-title .lv-close:hover {
  cursor: pointer;
}
.lavender-container.lv-dark-mode .lavender-title {
  background-color: #121212;
  color: white;
}

.lavender-container #lavender-welcome-container {
  text-align: center;
}

.lavender-container .lv-dark-trigger {
  position: absolute;
  right: 20px;
  bottom: 20px;
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.lavender-container .lv-border-btn {
  cursor: pointer;
}

.lavender-container .lv-border-btn div:first-child {
  text-align: center;
  font-weight: normal;
  letter-spacing: 0.1em;
  font-family: "Helvetica Neue", sans-serif;
  height: 30px;
  padding: 0 8px 0 8px;
  line-height: 30px;
  vertical-align: middle;
  display: inline-block;
  border: 2px solid #9370db;
}

.lavender-container .lv-border-btn div:nth-child(2) {
  height: 34px;
  width: 40px;
  background-repeat: no-repeat;
  background-color: #9370db;
  display: inline-block;
  background-position: center;
  background-size: 16px;
  background-image: url("https://sorter.s3.amazonaws.com/fontawesome/arrow-right-solid.svg");
  vertical-align: middle;
  margin-left: -4px;
}

.lv-highlight-options {
  text-align: left;
  margin: 8px;
}

.lv-highlight-options a {
  border-radius: 4px;
  border: 1px solid #7253d5;
  padding: 0 8px 0 8px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 22px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 8px;
}

.lv-highlight-options a.selected {
  background: #7253d5;
  color: white;
  box-shadow: 0 0 5px rgba(45, 62, 80, 0.2);
  cursor: pointer;
}

.lv-synonym-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  width: 100px;
  padding: 6px;
}
.lv-similar-name-helpful {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90px;
  padding-left: 8px;
}
.misspelled-name-tooltip {
  font-size: 10px;
}
.misspelled-name-tooltip .lv-synonym-row {
  margin-top: 8px;
}
.lv-similar-name-helpful p {
  color: #5c5c5c;
  font-size: 12px;
}
.lv-similar-name-helpful img {
  vertical-align: bottom;
  margin-top: 6px;
  font-size: 0.7em;
}

.lv-short-compose-box {
  background: none;
  border-radius: 4px;
  width: 300px;
  height: fit-content;
  height: -moz-fit-content;
  box-shadow: rgba(46, 42, 49, 0.5) 0px 8px 20px;
  z-index: 10001; /*Specific z-index for salesloft modal*/
  font-family: "Karla", sans-serif;
  /*padding: 0 8px 4px 8px;*/
  position: absolute;
  bottom: 20px;
  left: 650px;
  font-size: 12px;
  text-align: left;
  color: darkred;
  display: none;
  filter: opacity(0.9);
  transition: filter 0.2s linear;
}
.lv-short-compose-box.lv-email-coach-clicked {
  filter: opacity(1);
}
.lv-compose-tooltip {
  background: none;
  border-radius: 4px;
  width: fit-content;
  height: fit-content;
  box-shadow: rgba(46, 42, 49, 0.5) 0px 8px 20px;
  z-index: 10;
  position: absolute;
  /*top:0px;*/
  display: none;
  right: 0px;
}

/*TODO: delete this when safe*/
/*.lv-recs-cont > p{*/
/*  font-size: 10px;*/
/*  color: black;*/
/*  font-family: Lato, sans-serif;*/
/*  font-weight: normal;*/
/*  width: 330px;*/
/*  margin: 8px auto;*/
/*}*/

/*.lv-rec-line-break{*/
/*  border:0px;*/
/*  width:95%;*/
/*  border-top: 1px solid #EFE9E9;*/
/*  margin: 0 auto;  */
/*}*/
/*.lv-hl-rec .lv-rec-txt{*/
/*  font-family: 'Karla', sans-serif;*/
/*  width: 330px;*/
/*  margin: 4px auto;*/
/*  padding: 8px;*/
/*  color: #000000;*/
/*  font-weight: normal;*/
/*  font-size: 11px;*/
/*   box-sizing: border-box;*/
/*  -moz-box-sizing: border-box;*/
/*  -webkit-box-sizing: border-box;*/
/*  cursor: pointer;*/
/*}*/
/*.lv-hl-rec-top-bar{*/
/*  height: 18px;*/
/*  margin-bottom: 4px;*/
/*  background: linear-gradient(183deg, #7253D5 -17%, #8C6DEE 119%);*/
/*  border-radius: 4px 4px 0px 0px;*/
/*  width: 100%;*/
/*  position: relative;*/
/*}*/

/*.lv-hl-rec-synonym-line-break{*/
/*  border:0px;*/
/*  background-color:#F9F6FC;*/
/*  width:86%;*/
/*  height:3px;*/
/*  margin:auto; */
/*  margin-top:3px;*/
/*}*/

/*.lv-hl-synonym-list{*/
/*  height:auto;*/
/*  margin:8px auto;*/
/*  margin-left:10px;*/
/*  margin-right:2px;*/
/*}*/

/*.lv-synonym-row {*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  flex-wrap: wrap;*/
/*  width: 100%;*/
/*  align-items: center;*/
/*  color: #7253d5;*/
/*  border-radius: 4px;*/
/*  transition: all 50ms ease;*/
/*  font-size: 13px;*/
/*}*/

.compose-trigger-parent {
  position: absolute;
  display: block !important;
  width: 9px; /*Actual size dimension 25x35px*/
  box-sizing: content-box !important;
  height: 27px;
  top: 110px;
  right: 0px;
  background: #7253d5;
  box-shadow: 0px 4px 10px rgba(116, 50, 207, 0.3);
  border-radius: 3px 0px 0px 3px;
  z-index: 3 !important;
  padding: 4px 8px 4px 8px;
  transition: linear 0.3s;
  background-size: 18px;
  background-position: 4px center;
  background-repeat: no-repeat;
  overflow: visible;
}
.lv-white-flower-container {
  width: 20px;
  height: auto;
}

.lv-white-flower-img {
  width: 20px;
  height: auto;
}

.lv-cog-setting-container {
  width: 20px;
  height: auto;
}

.lv-cog-setting-img {
  width: 20px;
  height: auto;
  display: none;
}
.lv-ceq-btn {
  font-weight: bold;
  color: #49cece;
  font-family: Lato, sans-serif;
  font-size: 18px;
}
.lv-smart-btn {
  position: absolute;
  background: white;
  padding: 6px;
  border-radius: 100%;
  box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.25);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.25s linear;
}

.compose-trigger-parent.lv-slc {
  top: 140px;
}
.lv-button-container-parent.lv-slc {
  top: 60px;
}

.lv-need-help-getting-started {
  width: 120px !important;
  height: 30px;
}

.lv-need-help-getting-started > p {
  width: 97px;
  color: white;
  margin: 0px;
  margin-left: 25px;
  font-size: 12px;
  font-family: "karla", sans-serif;
}

.lv-button-container-parent {
  visibility: hidden;
  position: absolute;
  right: 0px;
  top: 30px;
  display: block !important;
}
.lv-button-container {
  position: absolute;
  z-index: 2 !important;
  background: white;
  box-shadow: -5px 0px 12px rgba(243, 230, 255, 0.8);
  width: 70px;
  height: 115px;
  right: 0px;
  border-bottom-left-radius: 90px;
  border-top-left-radius: 90px;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
  visibility: hidden;
  opacity: 0;
  margin-top: 43px;
}
.lv-button-container-parent.lv-vertical > .lv-button-container {
  width: 25px;
  height: fit-content;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  margin-top: 115px;
  flex-direction: column;
  row-gap: 10px;
  padding-top: 8px;
  padding-bottom: 4px;
  align-items: center;
}

.aSt .compose-trigger-parent {
  bottom: 58px;
}

.aSt .lv-short-compose-box {
  bottom: 98px;
}

#lavender-compose-container {
  overflow-y: overlay;
  position: relative;
  background: url("https://sorter.s3.amazonaws.com/lavender/images/page+loading.gif")
    no-repeat 100px 50%;
  background-size: 100px 100px;
  scrollbar-width: auto;
  scrollbar-color: rgba(140, 140, 140, 0.8) rgba(114, 83, 212, 0.2);
}
#lavender-compose-container::-webkit-scrollbar {
  width: 8px;
}
#lavender-compose-container::-webkit-scrollbar-track {
  background: rgba(114, 83, 212, 0.2);
}
#lavender-compose-container::-webkit-scrollbar-thumb {
  background: linear-gradient(
    90deg,
    rgba(114, 83, 212, 0.9) 6.37%,
    rgba(141, 109, 218, 0.8) 113.23%
  );
  border-radius: 20px;
}

#lavender-profile-container {
  position: relative;
  overflow-y: overlay;
  scrollbar-width: auto;
  scrollbar-color: linear-gradient(
      90deg,
      rgba(114, 83, 212, 0.9) 6.37%,
      rgba(141, 109, 218, 0.9) 113.23%
    )
    rgba(114, 83, 212, 0.2);
}
#lavender-profile-container::-webkit-scrollbar {
  width: 8px;
}
#lavender-profile-container::-webkit-scrollbar-track {
  background: rgba(114, 83, 212, 0.2);
}
#lavender-profile-container::-webkit-scrollbar-thumb {
  background: linear-gradient(
    90deg,
    rgba(114, 83, 212, 0.9) 6.37%,
    rgba(141, 109, 218, 0.9) 113.23%
  );
  border-radius: 20px;
}

.lv-scb-close-bar {
  position: absolute;
  /*background: gray;*/
  cursor: pointer;
  border-radius: 2px;
  height: 10px;
  width: 10px;
  right: 0;
  bottom: 4px;
  background-image: url("https://sorter.s3.amazonaws.com/lavender/images/times-solid.svg");
  background-repeat: no-repeat;
}

.lv-compose-notification {
  background: #ff5733;
  width: 10px;
  height: 10px;
  border-radius: 7px;
  position: absolute;
  left: 0;
  top: -3px;
  border: 1px solid white;
  z-index: 1;
  cursor: pointer;
}

.lv-email-notification {
  background: #ff5733;
  width: 10px;
  height: 10px;
  border-radius: 7px;
  position: absolute;
  border: 1px solid white;
  z-index: 1;
  cursor: pointer;
  margin-top: -2px;
  left: -3px;
  top: 0;
}
.lv-button-container-notification {
  visibility: visible;
  background: #ff5733;
  width: 25px;
  height: 3px;
  border-radius: 2px 0px 0px 0px;
  position: absolute;
  border: 0px;
  top: 80px;
  right: 0px;
  z-index: 100;
  display: none;
}
.lv-mp-notification {
  background: #ff5733;
  width: 10px;
  height: 10px;
  border-radius: 7px;
  position: absolute;
  left: -3px;
  top: -3px;
  border: 1px solid white;
  z-index: 1;
  cursor: pointer;
}

#lavender-iframe-compose {
  overflow-y: overlay;
  overflow-x: hidden;
}
#lavender-iframe-compose .lv-hl-trigger.selected {
  color: white;
  background-color: mediumpurple;
}

#lavender-real-welcome-container {
  position: relative;
  scrollbar-width: auto;
  scrollbar-color: rgba(114, 83, 212, 0.9), rgba(114, 83, 212, 0.2);
}
#lavender-real-welcome-container::-webkit-scrollbar {
  width: 8px;
}
#lavender-real-welcome-container::-webkit-scrollbar-track {
  background: rgba(114, 83, 212, 0.2);
}
#lavender-real-welcome-container::-webkit-scrollbar-thumb {
  background: linear-gradient(
    90deg,
    rgba(114, 83, 212, 0.9) 6.37%,
    rgba(141, 109, 218, 0.8) 113.23%
  );
  border-radius: 20px;
}

.table-profile {
  width: 300px;
  text-align: left;
  text-transform: none;
  padding: 8px 16px 8px 16px;
  font-weight: bold;
  font-size: 16px;
  font-family: Karla, sans-serif;
}

.table-profile-entry {
  width: 300px;
  height: 40px;
  font-size: 16px;
  text-align: left;
  text-transform: none;
  cursor: pointer;
  padding: 20px 16px 0 16px;
  line-height: 20px;
  border-top: 1px solid gray;
  font-family: Karla, sans-serif;
}

.modal-notification {
  position: fixed;
  left: 8px;
  top: 8px;
  width: fit-content;
  height: fit-content;
  background-color: white;
  font-size: 16px;
  border-radius: 10px;
  font-family: Karla, sans-serif;
  z-index: 10001;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.update-body > span {
  font-weight: bold;
}

.notification-logo {
  margin-top: 20px;
  margin-left: 5px;
  display: inline;
  width: 56px;
  height: 56px;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 10px rgba(116, 50, 207, 0.3));
}

.next-update {
  cursor: pointer;
  width: 30px;
  float: right;
  margin-right: 20px;
  margin-top: 18px;
  border-radius: 5px;
  padding-right: 2px;
  padding-top: 2px;
}

.lv-hover-profile-box {
  background: #ffffff;
  border: 2px solid #7253d5;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0 0 50px rgba(45, 62, 80, 0.2);
  font-family: "Karla", sans-serif;
  padding: 5px;
  position: absolute;
  top: 10px;
  right: 38px;
  font-size: 11px;
  text-align: left;
  color: darkred;
  max-width: 200px;
  overflow: hidden;
  display: none;
  z-index: 1;
  height: fit-content;
  width: 200px;
}

.lv-hover-profile-box > p {
  padding: 0px;
  margin: 0px;
  margin-bottom: 0px !important;
  color: #4e4e4e;
}

.lv-hover-gif-box {
  background: #ffffff;
  border: 2px solid #7253d5;
  border-radius: 2px;
  box-shadow: 0 0 50px rgba(45, 62, 80, 0.2);
  font-family: "Karla", sans-serif;
  padding: 5px;
  position: absolute;
  top: 28px;
  right: 4px;
  font-size: 11px;
  text-align: left;
  color: darkred;
  max-width: 200px;
  overflow: hidden;
  display: none;
  z-index: 1;
  height: fit-content;
  width: fit-content;
}

.lv-hover-gif-box > p {
  padding: 0px;
  margin: 0px;
  margin-bottom: 0px !important;
  color: #4e4e4e;
}
.lv-hover-mp-box {
  background: #ffffff;
  border: 2px solid #7253d5;
  box-sizing: border-box;
  box-shadow: 0 0 50px rgba(45, 62, 80, 0.2);
  font-family: "Karla", sans-serif;
  width: 91px;
  padding: 6px;
  position: absolute;
  top: -12px;
  right: 8px;
  font-size: 11px;
  text-align: left;
  max-width: 200px;
  overflow: hidden;
  display: none;
  z-index: 1;
}
.lv-hover-mp-box > p {
  padding: 0px;
  margin: 0px;
  margin-bottom: 0px !important;
  color: #4e4e4e;
}

.lv-hover-bullet-point-box {
  background: #ffffff;
  border: 2px solid #7253d5;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0 0 50px rgba(45, 62, 80, 0.2);
  font-family: "Karla", sans-serif;
  /* padding: 0 8px 4px 8px !important; */
  width: 90px;
  padding: 6px;
  position: absolute;
  top: 38px;
  right: -5px;
  font-size: 11px;
  text-align: left;
  max-width: 200px;
  overflow: hidden;
  display: none;
  z-index: 1;
}
.lv-hover-bullet-point-box > p {
  padding: 0px;
  margin: 0px;
  margin-bottom: 0px !important;
  color: #4e4e4e;
}

.lv-hover-lowtext-box {
  background: #ffffff;
  border: 2px solid #7253d5;
  border-radius: 2px;
  box-shadow: 0 0 50px rgba(45, 62, 80, 0.2);
  font-family: "Karla", sans-serif;
  padding: 8px;
  position: absolute;
  font-size: 11px;
  text-align: left;
  color: darkred;
  max-width: 200px;
  display: none;
  z-index: 1;
  width: 162px;
  right: 4px;
  top: -70px;
}
.lv-hover-lowtext-box > p {
  padding: 0px;
  margin: 0px;
  margin-bottom: 0px !important;
  color: #4e4e4e;
}

.lv-hover-lowtext-box > img {
  position: absolute;
  bottom: -11px;
  right: 15px;
  height: 11px;
  width: 11px;
}

.lv-click-to-lock-tooltip {
  background: #ffffff;
  border: 2px solid #7253d5;
  border-radius: 2px;
  box-shadow: 0 0 50px rgba(45, 62, 80, 0.2);
  font-family: "Karla", sans-serif;
  padding: 8px;
  position: absolute;
  font-size: 11px;
  text-align: left;
  color: darkred;
  max-width: 200px;
  display: none;
  z-index: 1;
  width: 162px;
  right: 4px;
  top: -50px;
}
.lv-click-to-lock-tooltip > p {
  padding: 0px;
  margin: 0px;
  margin-bottom: 0px !important;
  color: #4e4e4e;
}

.lv-click-to-lock-tooltip > img {
  position: absolute;
  bottom: -11px;
  right: 15px;
  height: 11px;
  width: 11px;
}

.gif-box {
  position: fixed;
  bottom: 20px;
  left: 615px;
  background-color: white;
  border-radius: 20px;
  width: 340px;
  height: 570px;
  z-index: 10005;
  box-shadow: 0 0 50px rgba(45, 62, 80, 0.2);
}

.gif-search-icon {
  width: 18px;
  height: 15px;
  object-fit: contain;
  position: absolute;
  top: 26px;
  left: 23px;
  z-index: 5;
}

.gif-container-col-2 .gif-item {
  width: calc(50% - 8px) !important;
}

@media only screen and (max-width: 1400px) {
  .gif-box {
    width: 240px;
    height: 400px;
  }
}

.gif-header {
  width: 100%;
  height: 64px;
  border-radius: 10px 10px 0 0;
  background: #7253d5;
  text-align: center;
  cursor: move;
}

.resize-top {
  position: absolute;
  width: 100%;
  height: 4px;
  /*left: 50%;*/
  /*margin-right: -50%;*/
  /*transform: translate(-50%, 0%);*/
  top: 0;
  cursor: ns-resize;
}

.resize-top > img {
  width: 60px;
  height: 20px;
}

.resize-bottom {
  position: absolute;
  width: 100%;
  height: 4px;
  /*left: 50%;*/
  /*margin-right: -50%;*/
  /*transform: translate(-50%, 0%);*/
  bottom: 0;
  cursor: ns-resize;
  z-index: 2;
}

.resize-side {
  position: absolute;
  width: 4px;
  height: 100%;
  right: 0;
  top: 0;
  cursor: ew-resize;
}

.resize-side > img {
  width: 20px;
  height: 60px;
  line-height: 100%;
  display: block;
  position: absolute;
  top: calc(50% - 30px);
  right: 0;
}

.resize-left {
  position: absolute;
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
  cursor: ew-resize;
}

.gif-close {
  position: absolute;
  width: 8px;
  height: 8px;
  top: 6px;
  right: 6px;
  cursor: pointer;
}

.gif-close > img {
  width: 100%;
  height: 100%;
  display: block;
}

.gif-draggable {
  position: absolute;
  width: 35px;
  height: 35px;
  top: 1%;
  right: 14%;
  cursor: grab;
}

.gif-draggable > img {
  width: 100%;
  height: 100%;
}

.gif-search {
  border-radius: 5px;
  border: 0px;
  width: calc(100% - 32px);
  height: 32px;
  /* max-height: 60px; */
  text-indent: 32px;
  font-size: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Karla, sans-serif;
  margin-top: 16px;
  /* margin-bottom: 10px; */
  color: #8846e3;
}

.gif-search:focus {
  outline: none;
}

.gif-drop-down-container {
  position: absolute;
  width: 100%;
  z-index: 2;
  display: none;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.gif-tag-label {
  height: 22px;
  font-size: 12px;
  color: #848383;
  background: #edebeb;
  padding-left: 16px;
  line-height: 22px;
  font-family: "Karla", sans-serif;
}

.gif-drop-down {
  background-color: white;
  height: auto;
  overflow-y: auto;
  max-height: 250px;
}

.gif-container {
  position: relative;
  width: 100%;
  height: calc(100% - 64px);
  background: white;
  border-radius: 0 0 10px 10px;
  overflow-y: auto;
  padding: 2%;
  padding-bottom: 0;
  box-sizing: border-box;
  scrollbar-width: none;
}

/* THIS ONLY WORKS FOR CHROME*/
.gif-container::-webkit-scrollbar {
  display: none;
}

.gif-drop-down-item {
  font-size: 16px;
  cursor: pointer;
  color: #848383;
  font-family: "Karla", sans-serif;
  padding: 8px 8px 8px 16px;
}

.gif-item {
  height: 100px;
  width: calc(33% - 8px);
  display: inline-block;
  /* background-color: #C4C4C4; */
  margin: 4px;
  border-radius: 5px;
  background: url("https://sorter.s3.amazonaws.com/lavender/images/page+loading.gif")
    no-repeat center;
  background-size: 34%;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.gif-item.grab {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.gif-item > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.out-of-gif-matches {
  font-size: 16px;
  font-family: montserrat, sans-serif;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #8846e3;
  text-align: center;
}

.lavender-envelope {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 3%;
  text-align: center;
}

.lavender-envelope > img {
  width: 30%;
  height: 30%;
  max-width: 110px;
  max-height: 110px;
}

.gif-flowers {
  text-align: center;
  width: 104%;
  left: -2%;
  position: relative;
  bottom: 0;
}

.gif-flowers > img {
  width: 50%;
  display: block;
  position: absolute;
  max-width: 220px;
  object-fit: contain;
  bottom: 0;
}
.gif-flowers img:nth-child(1) {
  left: 0;
}
.gif-flowers img:nth-child(2) {
  right: 0;
}

.mobile-preview-container {
  position: fixed;
  bottom: 20px;
  left: 650px;
  z-index: 10005;
  width: 377px;
  height: 828px;
  cursor: move;
  /*transform-origin: left bottom;*/
}

.mobile-preview-zoom-container {
  transform-origin: left top;
  position: absolute;
  left: 0;
  top: 0;
}

@media only screen and (max-width: 1800px) {
  .mobile-preview-zoom-container {
    transform: scale(0.9);
  }
  .mobile-preview-container {
    width: calc(377px * 0.9);
    height: calc(828px * 0.9);
  }
}
@media only screen and (max-height: 1080px) {
  .mobile-preview-zoom-container {
    transform: scale(0.9);
  }
  .mobile-preview-container {
    width: calc(377px * 0.9);
    height: calc(828px * 0.9);
  }
  .mobile-preview-body {
    padding-left: 9px;
    padding-right: 9px;
  }
}
@media only screen and (max-width: 1400px) {
  .mobile-preview-zoom-container {
    transform: scale(0.8);
  }
  .mobile-preview-container {
    width: calc(377px * 0.8);
    height: calc(828px * 0.8);
  }
  .mobile-preview-body {
    padding-left: 9px;
    padding-right: 9px;
  }
}
@media only screen and (max-height: 900px) {
  .mobile-preview-zoom-container {
    transform: scale(0.8);
  }
  .mobile-preview-container {
    width: calc(377px * 0.8);
    height: calc(828px * 0.8);
  }
  .mobile-preview-body {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media only screen and (max-width: 1200px) {
  .mobile-preview-zoom-container {
    transform: scale(0.75);
  }
  .mobile-preview-container {
    width: calc(377px * 0.75);
    height: calc(828px * 0.75);
  }
  .mobile-preview-body {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media only screen and (max-width: 1000px) {
  .mobile-preview-zoom-container {
    transform: scale(0.7);
  }
  .mobile-preview-container {
    width: calc(377px * 0.7);
    height: calc(828px * 0.7);
  }
  .mobile-preview-body {
    padding-left: 4px;
    padding-right: 4px;
  }
}
@media only screen and (max-height: 820px) {
  .mobile-preview-zoom-container {
    transform: scale(0.65);
  }
  .mobile-preview-container {
    width: calc(377px * 0.65);
    height: calc(828px * 0.65);
  }
}
@media only screen and (max-height: 640px) {
  .mobile-preview-zoom-container {
    transform: scale(0.58);
  }
  .mobile-preview-body {
    padding-left: 3px;
    padding-right: 3px;
  }
  .mobile-preview-container {
    width: calc(377px * 0.58);
    height: calc(828px * 0.58);
  }
}
@media only screen and (max-width: 800px) {
  .mobile-preview-zoom-container {
    transform: scale(0.58);
  }
  .mobile-preview-body {
    padding-left: 3px;
    padding-right: 3px;
  }
  .mobile-preview-container {
    width: calc(377px * 0.58);
    height: calc(828px * 0.58);
  }
}
.mobile-preview-close {
  width: 18px;
  height: 18px;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  position: absolute;
  padding: 8px;
  box-sizing: content-box;
  background: white;
  top: -16px;
  right: -16px;
  z-index: 1;
  border-radius: 100%;
  box-shadow: 0 0 50px rgba(45, 62, 80, 0.2);
}
.mobile-preview-drag {
  width: 80px;
  height: 30px;
  cursor: move;
  display: inline-block;
  margin-left: 42%;
  margin-bottom: 10px;
  margin-top: 0px;
}
.mobile-preview-drag > img {
  width: 100%;
}
.mp-blob-1 {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: -4px;
  left: 0px;
}
.mp-blob-2 {
  width: 10%;
  height: auto;
  position: absolute;
  bottom: 190px;
  right: 0px;
}
.mp-blob-3 {
  width: 5%;
  height: auto;
  position: absolute;
  bottom: 300px;
  left: -6px;
  transform: rotate(80deg);
}
.mp-blob-4 {
  width: 15%;
  height: auto;
  position: absolute;
  bottom: 220px;
  left: -12px;
  transform: rotate(80deg);
}
.mp-blob-5 {
  width: 15%;
  height: auto;
  position: absolute;
  bottom: 230px;
  right: -30px;
  transform: rotate(80deg);
}
.placeholder-phone-background {
  position: relative;
  text-align: center;
  border-radius: 30px;
  background-color: black;
  height: 812px;
  width: 375px;
  padding-top: 16px;
  background: rgb(138, 197, 207);
  margin: auto;
  cursor: move;
}
.placeholder-phone-screen {
  text-align: center;
  margin: auto;
  border-radius: 20px;
  background-color: white;
  height: 98%;
  width: 340px;
  overflow: hidden;
  cursor: default;
  margin-top: -1px;
}
.mobile-preview-iphone-time {
  position: absolute;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  background: #f3efef;
  padding-top: 8px;
  height: 25px;
  border-radius: 25px;
  width: 103px;
  z-index: 1;
}
.mobile-preview-iphone-top {
  background: rgb(138, 197, 207);
  width: 160px;
  height: 38px;
  border-radius: 0 0 20px 20px;
  position: relative;
  display: inline-block;
  margin-top: -15px;
  z-index: 10006;
}
.mobile-preview-iphone-top-bar {
  background: #e4e4df;
  width: 35px;
  height: 3px;
  margin-top: 21px;
  display: inline-block;
  position: absolute;
  left: 56px;
}
.mobile-preview-iphone-top-circle {
  background: #e4e4df;
  width: 9px;
  height: 9px;
  margin-left: 25px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  margin-top: 18px;
}
.mobile-preview-iphone-top-right {
  display: inline-block;
  position: absolute;
  margin-top: 0px;
  background-color: #f3efef;
  height: 27px;
  width: 116px;
  margin-left: -26px;
  border-radius: 25px;
}
.mobile-preview-signal {
  width: 15px;
  height: 10px;
  margin-left: 10px;
  margin-top: 9px;
  display: inline-block;
}
.mobile-preview-wifi {
  width: 15px;
  height: 10px;
  margin-left: 4px;
  display: inline-block;
}
.mobile-preview-battery {
  width: 21px;
  height: 10px;
  margin-left: 4px;
  display: inline-block;
}
.mobile-preview-upper-inbox {
  position: relative;
  background-color: #f3efef;
  height: 50px;
  background: #f3efef;
  margin-top: -7px;
  padding-top: 7px;
}
.mobile-preview-reload-button {
  width: 25px;
  height: 25px;
  left: -4px;
  top: -4px;
  position: absolute;
  cursor: pointer;
  border-radius: 100%;
  background: white;
  z-index: 1;
  padding: 4px;
}
.mobile-preview-reload-button svg {
  height: 20px;
  width: 20px;
  transform: rotate(-30deg);
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  margin: 2px;
}
.mobile-preview-reload-button svg path {
  fill: #bfa6ebff;
}
@keyframes ckw {
  0% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(330deg);
  }
}

.all-inboxes {
  text-align: left;
  float: left;
  margin-left: 3px;
  margin-top: 20px;
  display: inline-block;
}
.all-inboxes > img {
  margin-left: 5px;
  display: inline-block;
  width: 10px;
  height: 15px;
}
.all-inboxes > p {
  margin-left: 10px;
  display: inline-block;
  position: absolute;
  color: #147efb;
  margin-top: -2px;
}
.lv-mp-arrow-down {
  display: inline-block;
  float: right;
  margin-right: 24px;
  width: 10px;
  height: 18px;
  margin-top: 20px;
  transform: rotate(-90deg);
}
.lv-mp-arrow-up {
  display: inline-block;
  float: right;
  margin-right: 24px;
  width: 10px;
  height: 18px;
  margin-top: 20px;
  transform: rotate(90deg);
}
.mobile-preview-end-upper {
  border: 0px;
  width: 100%;
  border-bottom: 1px solid #4e4e4e;
  opacity: 20%;
  margin: 5px 0 10px 0;
}
.mobile-preview-recipient > img {
  display: inline-block;
  width: 45px;
  height: 45px;
  float: left;
  border-radius: 50%;
}
.mobile-preview-sent-time {
  position: absolute;
  right: 10px;
  color: #4e4e4e;
  opacity: 80%;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  margin-top: 0px;
}
.mobile-preview-recipient {
  margin-top: 10px;
  min-height: 50px;
}
.mobile-preview-from {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 15px;
  float: left;
  margin-top: 2px;
  margin-bottom: 1px;
  margin-left: 10px;
}
.mobile-preview-to {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 12px;
  margin-top: 0px;
  margin-left: 10px;
  float: left;
  width: 230px;
  text-align: left;
}
.mobile-preview-to > span {
  color: #4e4e4e;
  opacity: 80%;
}
.mobile-preview-subject {
  margin-left: 7px;
  margin-right: 10px;
  color: black;
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 30px;
  word-break: break-word;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  border: 0px;
  margin-top: 0px;
  float: left;
  margin-bottom: 5px;
  outline: none;
  width: 91%;
  resize: none;
  height: auto;
}
.mobile-preview-divider {
  background-color: black;
  height: 1px;
  width: 100%;
  border: 0px;
}
.mobile-preview-body-container {
  position: absolute;
  min-height: 550px;
  max-height: 656px;
  max-width: 87%;
  min-width: 80%;
  margin-left: 10px;
  text-align: left;
  overflow: auto;
  font-family: "karla", sans-serif;
  overflow-y: overlay;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: rgba(140, 140, 140, 0.8) rgba(114, 83, 212, 0.2);
}
.mobile-preview-body-container::-webkit-scrollbar {
  width: 0px;
  scrollbar-width: none;
}
.mobile-preview-body-scrollbar::-webkit-scrollbar {
  width: 3px !important;
}
.mobile-preview-body-scrollbar::-webkit-scrollbar-track {
  background: rgba(114, 83, 212, 0.2);
}
.mobile-preview-body-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(140, 140, 140, 0.8);
  border-radius: 20px;
}

/* .mobile-preview-body-container::-webkit-scrollbar-track{
}
.mobile-preview-body-container::-webkit-scrollbar-thumb{
} */
.mobile-preview-body {
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
  max-width: 98%;
  cursor: text;
  padding-bottom: 20px;
}
.mobile-preview-font {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
  background: transparent;
  color: rgb(1, 1, 1);
}
.mobile-preview-footer {
  width: 100%;
  height: 50px;
  margin-top: 666px;
  left: 0;
  background-color: #f3efef;
  border-top: 1px solid rgba(78, 78, 78, 0.2);
}
.mobile-preview-footer-inbox-icons {
  width: 100%;
  margin: auto;
  display: flex;
  padding-top: 12px;
}
.mobile-preview-footer-inbox-icons > img {
  width: 20px;
  display: flex;
  margin: auto;
  height: 20px;
}
.mobile-preview-floating-bar {
  position: relative;
  background-color: #8e8181;
  width: 148px;
  height: 5px;
  margin: auto;
  top: 8px;
  border-radius: 10px;
}
.mp-darkMode {
  color: rgb(254, 254, 254) !important;
  background-color: #2a292b !important;
}
.website-demo-profile-prompt {
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 50px rgba(45, 62, 80, 0.2);
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  padding: 0 8px 4px 8px;
  position: absolute;
  bottom: 55px;
  right: 0;
  font-size: 12px;
  text-align: left;
  color: darkred;
  max-width: 200px;
  overflow: hidden;
  display: block;
}
.lv-read-room-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 20px;
}
.lv-read-room-icon img {
  width: inherit;
  height: inherit;
}
.lv-usage-tooltip-container {
  position: absolute;
  z-index: 1;
  width: 0px;
  transition: width 0.5s ease-out;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 12px 34px rgba(114, 83, 219, 0.2);
}
.lv-usage-tooltip {
  background-color: white;
  padding: 4px;
  box-sizing: border-box;
  height: 24px;
  width: auto;
  border-radius: 4px 0 0 4px;
  border: 0.5px solid #e5e5e5;
  border-right: 0px;
  align-items: center;
  display: flex;
}
.lv-usage-tooltip-notif-bar {
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 4px 0 0 4px;
  width: 8px;
  height: 24px;
  background: #ff5733;
  display: inline-block;
}
.lv-usage-tooltip-image {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: inherit;
  margin: 0px;
  margin-right: 4px;
  margin-left: 9px;
}
.lv-usage-tooltip-image img {
  width: 15px;
  height: 15px;
  vertical-align: unset;
  display: block;
}
.lv-usage-tooltip-text {
  width: auto;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0 2px;
}
.lv-usage-tooltip-text p {
  color: #4e4e4e;
  font-size: 10px;
  font-family: "Karla", sans-serif;
  font-weight: normal;
  margin: unset;
}

.lv-outreach-text-modal {
  height: auto;
  width: 550px;
  margin: auto;
  min-height: 200px;
  position: absolute;
  color: black;
  text-align: center;
  box-shadow: 0 4px 50px rgba(45, 62, 80, 0.2);
  border-radius: 10px;
  z-index: 500;
  overflow: hidden;
  top: 50%;
  right: 50%;
  background-color: #ffffff;
  padding-bottom: 7px;
}
.lv-outreach-text-modal-header {
  background: linear-gradient(183.99deg, #7253d5 -17.61%, #9e6ed4 119.19%);
  width: inherit;
  height: 35px;
  cursor: move;
}

.lv-close-outreach-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: 3px;
  color: white;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.lv-outreach-modal-logo {
  width: 20px;
  height: 17px;
  float: left;
  margin-top: 8px;
  margin-left: 10px;
  display: inline-block;
}
.lv-outreach-modal-logo-text {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  line-height: 20px;
  color: #ffffff;
  float: left;
  font-size: 18px;
  margin-left: 6px;
  margin-top: 8px;
  font-weight: 400;
}

.lv-outreach-modal-text-container {
  width: 95%;
  height: 95%;
  min-height: inherit;
  padding: 10px;
  background: rgb(255, 255, 255);
  margin-top: 10px;
  margin-left: 10px;
  box-shadow: 0px 10px 60px rgba(226, 215, 234, 0.3);
  border-radius: 5px;
  padding: 8px;
  font-size: 11px;
  border: 0px;
  text-align: left;
  overflow-y: auto;
  font-family: "Lato", sans-serif;
}

.lv-outreach-modal-email-analysis-text {
  margin-top: 23px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4e4e4e;
  text-align: left;
  text-indent: 25px;
}

.lv-outreach-modal-sidebar-container {
  width: 95%;
  margin-top: 10px;
}
.lv-outreach-modal-buttons-header {
  font-family: "Karla", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: #707070;
  mix-blend-mode: normal;
  opacity: 0.61;
  text-align: left;
  text-indent: 20px;
  margin-top: 10px;
}
.lv-outreach-modal-buttons-container {
  background-color: white;
  box-shadow: 0px 10px 60px rgba(226, 215, 234, 0.3);
  border-radius: 5px;
  width: 95%;
  min-height: 29px;
  width: 85%;
  margin-left: 20px;
  display: block;
  padding-top: 3px;
  text-align: left;
  padding: 2px;
}

.lv-outreach-modal-tone-highlight {
  font-family: "Karla", sans-serif;
  color: #4e4e4e;
  border: 2px solid rgba(147, 104, 211, 0.8);
  margin: 2px 2px 7px 2px;
  border-radius: 7px;
  font-size: 9px;
  padding: 2px 8px;
  line-height: 17px;
  cursor: pointer;
  display: inline-flex;
  text-transform: uppercase;
  background: #f8f8f8;
  width: auto;
  text-align: center;
  opacity: 80%;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
}
.lv-outreach-modal-tone-highlight:hover {
  opacity: 1;
}
.lv-outreach-modal-clarity-highlight {
  font-family: "Karla", sans-serif;
  color: #4e4e4e;
  border: 2px solid rgba(255, 157, 157, 0.8);
  margin: 2px 2px 7px 2px;
  border-radius: 7px;
  font-size: 9px;
  padding: 2px 8px;
  line-height: 17px;
  cursor: pointer;
  display: inline-flex;
  text-transform: uppercase;
  background: #f8f8f8;
  width: auto;
  text-align: center;
  opacity: 80%;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
}
.lv-outreach-modal-clarity-highlight:hover {
  opacity: 1;
}

.lv-outreach-modal-ready-highlight {
  font-family: "Karla", sans-serif;
  color: #4e4e4e;
  border: 2px solid rgba(165, 229, 157, 0.8);
  margin: 2px 2px 7px 2px;
  border-radius: 7px;
  font-size: 10px;
  padding: 4px;
  line-height: 17px;
  display: inline-flex;
  text-transform: uppercase;
  background: #f8f8f8;
  width: auto;
  padding: 4px;
  text-align: center;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
}
.lv-outreach-modal-not-ready {
  font-family: "Karla", sans-serif;
  background: #ff9d9d;
  color: white;
  margin: 2px 2px 7px 2px;
  border-radius: 7px;
  font-size: 9px;
  padding: 2px 8px;
  height: 16px;
  line-height: 17px;
  display: inline-flex;
  text-transform: uppercase;
  width: auto;
  padding: 4px;
  text-align: center;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
}
.lv-outreach-modal-tone-highlight > p {
  text-align: center;
  width: inherit;
}
.lv-outreach-modal-clarity-highlight > p {
  text-align: center;
  width: inherit;
}
.lv-outreach-modal-ready-highlight > p {
  text-align: center;
  width: inherit;
}

.lv-outreach-modal-update-email {
  background: #7b4cdb;
  /*box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);*/
  border-radius: 4px;
  color: white;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  height: 36px;
  text-transform: capitalize;
  cursor: pointer;
  margin: 16px 8px;
  width: calc(100% - 16px);
}

.lv-outreach-modal-tone-highlight.selected {
  background-color: rgb(147, 104, 211);
  opacity: 1;
  color: white;
}
.lv-outreach-modal-clarity-highlight.selected {
  background-color: rgba(255, 157, 157, 0.8);
  opacity: 1;
  color: white;
}

.lv-outreach-read-check-mark {
  position: absolute;
  width: 10px;
  height: 10px;
  margin-top: -8px;
  margin-left: 67px;
}

.lv-upgrade-warning {
  position: absolute;
  z-index: 1;
  margin: auto;
  text-align: center;
}

.lv-upgrade-text {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #894ae7;
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
}
.lv-upgrade-link {
  position: relative;
  font-family: "Karla", sans-serif;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  padding: 8px;
  background: linear-gradient(180deg, #6de7c4 0%, #78cfce 100%);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: none;
}

.mobile-preview-lavender-lock {
  width: 60%;
  height: auto;
  margin: auto;
  text-align: center;
}

.lv-bullet-point-trigger {
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
  vertical-align: unset;
}
.lv-bullet-ai-btn {
  display: flex;
  justify-content: center;
  color: #7253d5;
  border: 1px solid #7253d5;
  padding: 7px;
  font-size: 0.875rem;
  border-radius: 18px;
  text-align: center;
  cursor: pointer;
}
.lv-bullet-ai-btn:hover {
  background-color: #f0f0f0;
}
.lv-bpe-tooltip {
  border-radius: 50%;
  padding: 0px 9px;
  background: #e6e6fa;
  border: none;
  text-align: center;
  position: relative;
  display: inline-block;
  font-size: 9px;
  font-weight: 800;
}
.lv-bullet-ai-btn:hover .lv-bpe-tooltip {
  background: #7253d5;
  color: white;
}

.lv-bullet-point-box,
.lv-edit-email-box:not(.lv-edit-email-box-modal-container) {
  width: 92%;
  /*margin:auto;*/
  margin-left: 3%;
  background-color: #faf5ff;
  border-radius: 4px;
  position: relative;
  padding: 6px;
  margin-top: 0px;
  max-width: 520px;
  white-space: normal;
  -webkit-user-modify: initial;
}

.lv-bullet-point-box {
  display: none;
  margin-left: 3%;
  z-index: 1;
}

.lv-bullet-point-box-body:not(.lv-edit-email-box-modal) {
  width: 100%;
  height: 100%;
  margin-top: -6px;
}

.lv-bullet-point-close:not(.lv-close) {
  position: absolute;
  top: 9px;
  right: 9px;
  line-height: 3px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}
.lv-bullet-point-gpt3 {
  position: absolute;
  bottom: 11px;
  left: 9px;
  width: 83px;
  height: 18px;
}
.lv-bullet-point-hint-container {
  margin: 2px 0px 11px 6px;
  font-family: "Karla", sans-serif;
  font-weight: 500;
  font-style: italic;
  color: #4e4e4e;
  font-size: 11px;
  line-height: 5px;
}
.lv-bullet-point-hint {
  font-family: "Karla", sans-serif;
  display: inline-block;
  margin: 0px;
  line-height: 15px;
}
.lv-bullet-point-hint-click {
  font-family: "Karla", sans-serif;
  color: #7353d5;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
}
.lv-bullet-point-full-hints {
  font-family: "Karla", sans-serif;
  margin: 10px 0 0 10px;
  line-height: 20px;
  display: none;
  font-style: normal;
}
.lv-bp-switch {
  background-color: #ccc;
  position: relative;
  display: inline-block;
  width: 48px;
  height: 16px;
  vertical-align: -6px;
  margin-top: 12px;
  margin-left: 20px;
  border-radius: 36px;
  cursor: pointer;
}
.lv-bp-menu-box {
  display: flex;
  opacity: 0;
  display: none;
  position: absolute;
  width: 135px;
  height: fit-content;
  background: #ffffff;
  border: 1px solid #e0dddd;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  bottom: 40px;
  padding: 6px 4px;
}
.lv-bp-menu-box div {
  height: 24px;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  border: 1px solid transparent;
}
.inactive-menu-item:hover {
  border: 1px solid transparent !important;
  box-shadow: none !important;
  border-radius: 0px !important;
}
.inactive-cold-email-tooltip {
  display: flex;
  opacity: 0;
  display: none;
  position: absolute;
  width: 142px;
  height: 25px;
  background: #ffffff;
  border: 1px solid #e0dddd;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  bottom: 45px;
  padding: 6px 4px;
  z-index: 21;
}
.inactive-cold-email-tooltip:after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #e0dddd;
}
.inactive-cold-email-tooltip > p {
  font-family: "Lato", sans-serif;
  font-size: 10px;
  line-height: 10px;
  color: #4e4e4e;
  margin: 0;
}

.lv-bp-menu-box div:hover {
  border: 1px solid #af8fdf;
  box-shadow: 0px 10px 60px rgba(226, 215, 234, 0.3);
  border-radius: 4px;
}
.lv-bp-menu-box img {
  width: 14px;
  height: 14px;
  display: inline-block;
  margin: 4px 4px;
}
.lv-bp-menu-box p {
  font-family: "Lato", sans-serif;
  font-size: 10px;
  line-height: 24px;
  color: #4e4e4e;
  display: inline-block;
  margin: 0 6px;
}
.lv-bp-email-type-tooltip {
  background: #ffffff;
  color: #4e4e4e;
  border: 1px solid #7253d5;
  box-sizing: border-box;
  box-shadow: 0 0 50px rgba(45, 62, 80, 0.2);
  font-family: "Karla", sans-serif;
  width: fit-content;
  padding: 4px;
  position: absolute;
  font-size: 10px;
  text-align: left;
  max-width: 200px;
  overflow: hidden;
  display: none;
  z-index: 1;
  bottom: 40px;
}
.lv-bp-calculating,
.lv-bp-header,
.lv-edit-email-error {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #7353d5;
  letter-spacing: 0.0125em;
  display: inline-block;
}
.lv-bp-calculating,
.lv-bp-header {
  margin: 9px 0px 9px 6px;
}
.lv-bp-calculating,
.lv-edit-email-error {
  display: none;
}
.lv-edit-email-error {
  margin: 9px 0px 3px 6px;
}
.lv-bullet-point-recipient {
  font-size: 12px;
  font-family: "Karla", sans-serif;
  color: #4e4e4e;
  font-weight: 500;
  background-color: transparent;
  border: 0px;
  height: 25px;
  width: 65% !important;
  /*margin-left:30px;*/
}
.lv-bullet-point-recipient::placeholder {
  color: gray;
}
.lv-bullet-point-recipient:focus {
  background-color: white;
  outline: none;
  border-radius: 4px;
  border: 2px solid #7353d5 !important;
}
.lv-bullet-point-result {
  font-family: "Karla", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #4e4e4e;
  margin-left: 10px;
  width: 91%;
  margin-bottom: 10px;
  display: none;
}
.lv-edit-email-box .lv-bullet-point-result {
  width: 100%;
}
.lv-bullet-point-box-body-content {
  display: flex;
}
.lv-bullet-point-lavender-logo {
  width: 32%;
  margin-top: 12px;
  margin-left: 3px;
  text-align: left;
  max-width: 200px;
}
.lv-bp-form-container {
  width: 98%;
  text-align: left;
  margin-bottom: 12px;
}
.lv-bp-form-container > p {
  color: #4e4e4e;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  margin: 0 0 16px 11px;
  position: relative;
  line-height: 18px;
}
.lv-bp-form-container > a {
  padding: 3px;
  font-family: "karla", sans-serif;
  color: white;
  background-color: #7353d5;
  border-radius: 2px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  text-decoration: none;
}

.lv-bullet-point-box-body > ul {
  list-style: none;
  margin: 0px;
  padding-left: 10px;
}
.lv-bullet-point-box-body > ul li {
  padding: 0px;
  line-height: 0px;
  margin: 0px;
}
.lv-bullet-point-box-body > ul li::before {
  content: "\2022";
  /*width:25px;*/
  margin-right: 10px;
  /*font-size:36px;*/
  vertical-align: middle;
  /*line-height:42px;*/
  /*max-height:30px;*/
  display: inline-block;
  font-size: 0;
  /* line-height: 73px; */
  /* height: 27px; */
  width: 6px;
  height: 6px;
  border-radius: 4px;
  margin-left: 8px;
  background-color: #7353d5;
}
.lv-bullet-point-box-body > ul > li > input {
  font-size: 12px;
  font-family: "Karla", sans-serif;
  color: #4e4e4e;
  font-weight: 500;
  background-color: transparent;
  border: 0px;
  height: 27px;
  width: 55%;
  box-sizing: border-box;
  vertical-align: middle;
}
.lv-bullet-point-box-body > ul > li > input::placeholder {
  color: gray;
}
.lv-bullet-point-box-body > ul > li > input:focus {
  background-color: white;
  outline: none;
  border-radius: 4px 0 0 4px;
  border: 2px solid #7353d5 !important;
  border-right: 0px;
  display: inline-block;
  vertical-align: middle;
}
.lv-bullet-point-box-body > ul > li > p {
  /*position:absolute;*/
  margin: 0 0 0 -8px;
  /*margin-top:6px;*/
  width: 49px;
  line-height: 24px;
  font-size: 14px;
  font-family: "karla", sans-serif;
  font-weight: 700;
  display: inline-block;
  color: #7353d5;
  cursor: pointer;
  opacity: 0;
  background-color: white;
  border: 2px solid #7353d5;
  border-left: 0px;
  border-radius: 0px 4px 4px 0px;
  text-align: center;
  height: 27px;
  box-sizing: border-box;
  vertical-align: middle;
}
.lv-bullet-point-box-body > ul > li > img {
  position: absolute;
  margin-top: 10px;
  width: 10px;
  height: 10px;
  margin-left: 55px;
  display: none;
  vertical-align: middle;
  cursor: pointer;
}
.lv-bullet-point-box-body > ul > li > span {
  display: none;
  margin-top: 7px;
  margin-left: 28px;
  color: red;
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 9px;
}

.lv-bullet-point-bottom-bar,
.lv-bullet-point-subject-bottom-bar,
.lv-bullet-point-body-bottom-bar {
  line-height: normal;
  margin-top: 10px;
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: flex-end;
}

.lv-edit-email-box-modal-container .lv-bullet-point-bottom-bar,
.lv-edit-email-box-modal-container .lv-bullet-point-subject-bottom-bar,
.lv-edit-email-box-modal-container .lv-bullet-point-body-bottom-bar {
  justify-content: flex-start;
}

.lv-bullet-point-bottom-bar > img {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 16px;
  background-color: white;
  border-radius: 4px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  cursor: pointer;
}

.lv-bullet-point-warning-click {
  font-family: "Karla", sans-serif;
  font-size: 12px;
  font-weight: bold;
  background: #707070;
  background-color: white;
  position: absolute;
  bottom: 40px;
  right: 20px;
  border-radius: 2px;
  width: 190px;
  padding: 5px;
  border-radius: 5px;
  display: none;
}
.lv-bullet-point-start-btn {
  font-family: "Karla", sans-serif;
  font-size: 12px;
  font-weight: bold;
  border: 0px;
  display: inline-block;
  background: #b7b4b4;
  border-radius: 4px;
  color: white;
  width: 82px;
  height: 24px;
  cursor: pointer;
  margin-right: 8px;
  padding: 0px;
}
.lv-bullet-point-try-again-btn {
  font-family: "Karla", sans-serif;
  font-size: 12px;
  border: 1.5px solid #7253d5;
  background: transparent;
  display: inline-block;
  border-radius: 4px;
  color: #7253d5;
  width: 82px;
  height: 24px;
  cursor: pointer;
  display: none;
  padding: 0px;
}
.lv-bullet-point-insert-btn,
.lv-edit-email-insert-btn,
.lv-edit-email-subject-insert-btn,
.lv-edit-email-body-insert-btn {
  font-family: "Karla", sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin-left: 20px;
  display: inline-block;
  border-radius: 4px;
  color: white;
  width: 82px;
  height: 24px;
  cursor: pointer;
  margin-right: 3px;
  padding: 0px;
}

.lv-bullet-point-insert-btn {
  background: #7253d5 !important;
  border: 0px;
  display: none;
}

.lv-edit-email-insert-btn,
.lv-edit-email-subject-insert-btn,
.lv-edit-email-body-insert-btn {
  background: #7253d5;
  border: 1px solid #7253d5;
}

.lv-edit-email-box-modal-container .lv-edit-email-insert-btn,
.lv-edit-email-box-modal-container .lv-edit-email-body-insert-btn {
  margin-left: 6px;
}

.lv-edit-email-box-modal-container .lv-edit-email-subject-insert-btn {
  margin-left: 0px;
}
.lv-edit-email-result-body-container {
  width: 92%;
}
.lv-bullet-point-proceed-anyway {
  font-family: "Karla", sans-serif;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  border: 1.5px solid #7253d5;
  background: transparent;
  border-radius: 4px;
  color: #7253d5;
  width: 132px;
  height: 24px;
  float: right;
  cursor: pointer;
  padding: 0px;
  margin-left: 16px;
  margin-right: 12px;
  line-height: 10px;
  text-align: center;
  text-decoration: none;
}
.lv-bp-general-warning {
  font-family: "karla", sans-serif;
  font-size: 12px;
  font-style: italic;
  margin: 2px 0px 11px 6px;
  color: #ff5733;
  display: none;
}

.lv-bullet-point-settings-btn {
  font-family: "Karla", sans-serif;
  font-size: 12px;
  font-weight: bold;
  border: 0px;
  display: inline-block;
  background: #7253d5;
  border-radius: 4px;
  color: white;
  width: 108px;
  height: 24px;
  float: right;
  cursor: pointer;
  padding: 0px;
  margin-left: 16px;
  margin-right: 12px;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
}

.lv-button-point-settings-btn:focus {
  background-color: #7353d5;
}

.lv-bullet-point-start-btn.active-bullet-point:active {
  color: white;
}
.lv-bullet-point-start-btn.active-bullet-point {
  background: #7353d5;
  cursor: pointer;
  color: white;
}

.lv-email-summary-icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-left: 20px;
  display: none;
  cursor: pointer;
}
.lv-email-summary-icon img {
  width: inherit;
  height: inherit;
}

.lv-summary-box {
  position: relative;
  width: 80%;
  height: auto;
  background: #faf5ff;
  border-radius: 10px;
  padding: 8px;
  margin: 10px 0px;
  display: none;
}
.lv-summary-body {
  width: 94%;
  height: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 4px auto auto 10px;
}
.lv-summary-icon {
  flex-grow: 1;
  max-height: 18px;
  max-width: 18px;
  margin-top: 4px;
}
.lv-email-summary-close {
  position: absolute;
  top: 8px;
  right: 8px;
  height: 10px;
  width: 10px;
  cursor: pointer;
}
.lv-summary-body > h3 {
  flex-grow: 1;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  color: #965ce7;
  margin: 0px;
  margin-left: 12px;
}
.lv-summary-body > div {
  margin-left: 12px;
  flex-grow: 3;
  font-family: "Karla", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #4e4e4e;
  background: url("https://sorter.s3.amazonaws.com/lavender/images/page+loading.gif")
    no-repeat 50px 50%;
  background-size: 50px 50px;
  min-height: 50px;
}
.email-summary-tooltip {
  position: absolute;
  width: 165px;
  white-space: normal;
  text-align: left;
  display: none;
  z-index: 10;
  box-shadow: 0px 0px 10px rgba(46, 42, 49, 0.5);
  padding: 8px;
  background-color: white;
  top: 30px;
  left: -82px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.2s linear;
  align-items: center;
}
.email-summary-tooltip > img {
  height: 26px;
  width: 26px;
}
.email-summary-tooltip > p {
  font-family: "karla", sans-serif;
  color: #4e4e4e;
  font-size: 12px;
  margin: 0px;
  white-space: normal;
  flex-basis: 82%;
  margin-left: 8px;
}
.email-summary-tooltip:after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.mobile-preview-optimize-btn {
  display: none;
  width: 160px;
  height: 30px;
  background-color: white;
  border: 2px solid #7353d5;
  color: #4e4e4e;
  font-family: "karla", sans-serif;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  position: absolute;
  margin: 0px;
  bottom: 29px;
  left: 105px;
  line-height: 30px;
}
.mobile-preview-optimize-btn.selected {
  background-color: #7353d5 !important;
  color: white !important;
}
/*Sales loft small screen, lavender blocking editor */
@media only screen and (max-width: 1600px) {
  #action-modal-container .modal-dialog {
    margin-left: 50px !important;
  }
}
/*salesloft hide profile lookup for certain compose boxes*/
form[name="templateForm"] .lavender-compose-profile-container,
form[name="stepForm"] .lavender-compose-profile-container,
#create-edit-modal .lavender-compose-profile-container {
  display: none !important;
}

#lavender-everywhere {
  width: 910px;
  height: 100vh;
  border-left: 1px solid #7353d5;
  background: white;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10001;
}
.lavender-everywhere-btn-container {
  position: fixed;
  top: calc(50% - 128px);
  right: 0;
  z-index: 10001;
  height: 32px;
  overflow: hidden;
}
.lavender-everywhere-btn-container:hover {
  height: fit-content;
}
.lavender-everywhere-btn {
  width: 24px;
  height: 32px;
  background-color: #7353d5;
  cursor: pointer;
  background-image: url("https://app.trylavender.com/public/img/logos/white-flower.svg");
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
}
.lavender-everywhere-drag-trigger {
  background-repeat: no-repeat;
  background-size: 14px;
  /* background-position: center; */
  height: 10px;
  background-color: #7353d5;
  background-position-x: center;
  width: 24px;
  cursor: move;
  background-image: url("https://app.trylavender.com/public/img/navigation/draggable.svg");
}
.lavender-everywhere-btn.open {
  background-image: url("https://app.trylavender.com/public/img/navigation/white-arrow.svg");
  background-size: 8px;
}
#lavender-everywhere iframe {
  width: 100%;
  height: calc(100% - 32px);
  border: 0;
}
.lavender-everywhere-header {
  width: 100%;
  height: 38px;
  background: #7353d5;
}
.lavender-everywhere-header .img-container {
  padding: 8px 12px;
  font-size: 16px;
}
.lavender-everywhere-header .img-container img:first-child {
  margin-right: 4px;
}
.lavender-everywhere-header .img-container img {
  height: 10px;
}
.lv-log-in-modal {
  padding: 30px;
  position: fixed;
  right: 32px;
  top: 32px;
  background: white;
  z-index: 10006;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  box-sizing: border-box;
}
.lv-log-in-modal .lv-in-app-billing-container {
  width: 100%;
  height: 160px;
}
.lv-log-in-modal .lv-teams-billing-container,
.lv-log-in-modal .lv-teams-annual-billing-container,
.lv-log-in-modal .lv-starter-annual-billing-container {
  width: 100%;
  height: 230px;
}
.lv-in-app-billing-container iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.lv-log-in-modal h1 {
  font-family: Lato, sans-serif;
  font-weight: 500;
  font-size: 21px;
  color: #4e4e4e;
  margin: 0;
  padding: 0;
}
.lv-log-in-modal h1 img {
  height: 20px;
  vertical-align: -2px;
  border-radius: 2px;
  margin-right: 8px;
}
.lv-log-in-modal p {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 15px;
  font-family: "Karla", sans-serif;
  color: #4e4e4e;
  line-height: 20px;
}
.lv-log-in-modal .close {
  position: absolute;
  right: 16px;
  top: 16px;
  height: 16px;
  cursor: pointer;
}
.lv-log-in-modal a {
  border: 1px solid #707070;
  color: #707070;
  margin-right: 16px;
  border-radius: 4px;
  width: 30%;
  max-width: 158px;
  height: 48px;
  line-height: 48px;
  vert-align: middle;
  font-size: 15px;
  font-family: "Karla", sans-serif;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
}
.lv-log-in-modal .lv-dont-ask-again {
  width: fit-content;
  color: #7e7e7e;
  text-decoration: underline;
  height: 14px;
  cursor: pointer;
  font-family: Lato, sans-serif;
  text-align: center;
  margin: 12px auto -12px;
}
.lv-log-in-modal .prpl-btn {
  background: #7253d5;
  color: white;
  border-color: #7253d5;
  margin-right: 0;
}

.lv-profile-lookup-box {
  height: fit-content;
  height: -moz-fit-content;
  box-shadow: rgba(46, 42, 49, 0.5) 0px 8px 20px;
  z-index: 10001; /*Specific z-index for salesloft modal*/
  position: absolute;
  bottom: 20px;
  left: 650px;
  line-height: initial;
  border-radius: 0 0 4px 4px;
}
.lv-sm-tooltip {
  font-family: Lato, sans-serif;
  color: #4e4e4e;
  z-index: 10002;
  font-size: 11px;
  position: fixed;
  padding: 4px 8px;
  background: white;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.lv-billing-card-data {
  margin-top: 16px;
  width: 320px;
}
.lv-billing-card-item {
  display: flex;
  align-items: center;
  margin: 16px;
}
.lv-billing-card-item img {
  height: 12px;
  margin-right: 16px;
}
.lv-billing-card-item p {
  margin-top: 0;
  margin-bottom: 0;
}
.lv-billing-card-item.lv-missing-feature p {
  color: lightgray;
  text-decoration: line-through;
}
.lv-pro-details {
  display: none;
}
.lv-starter-details {
  display: none;
}
.annual-buttons {
  display: none;
  justify-content: space-around;
}
.monthly-buttons {
  display: flex;
  justify-content: space-around;
}
.lv-log-in-modal .lv-pro-btn,
.lv-log-in-modal .lv-teams-btn,
.lv-starter-btn {
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  font-weight: 600;
  color: #707070;
  font-size: 14px;
  /*padding: 8px 24px;*/
  font-family: Lato, sans-serif;
}

.lv-pro-annual-billing-container {
  display: none;
}
.lv-starter-annual-billing-container {
  display: none;
}
.lv-teams-annual-billing-container {
  display: none;
}

.lv-log-in-modal .lv-pro-btn.lv-active,
.lv-log-in-modal .lv-teams-btn.lv-active,
.lv-starter-btn.lv-active {
  color: #7253d5;
  text-decoration: underline;
}

/*override intro js*/
.introjs-tooltipReferenceLayer * {
  font-family: Lato, sans-serif;
  color: #707070;
}
.introjs-tooltip-title {
  color: #4e4e4e;
}
/*.lv-button-container {*/
/*  z-index: auto !important;*/
/*}*/
.introjs-tooltiptext {
  padding: 8px 20px;
}
.introjs-prevbutton {
  background: white;
}
.introjs-nextbutton {
  background: #7253d5;
  color: white;
  text-shadow: none;
  border-color: #7253d5;
}
.introjs-nextbutton:hover,
.introjs-nextbutton:focus,
.introjs-nextbutton:active {
  background: #7353d5;
  color: white;
  box-shadow: none;
}
.introjs-helperLayer {
  box-shadow: rgba(115, 83, 213, 0.8) -1px 0px 1px 2px,
    rgba(33, 33, 33, 0.8) 0px 0px 0px 5000px !important;
}
.lv-tooltip {
  position: absolute;
  background-color: white;
  border-radius: 4px;
  max-width: 200px;
  padding: 8px;
  width: fit-content;
  box-shadow: 0 0 50px rgba(45, 62, 80, 0.2);
  z-index: 10002;
  border-right: 4px solid #7253d5;
}
.lv-tooltip:before {
  content: "\A";
  border-style: solid;
  border-width: 8px 12px 8px 0;
  border-color: transparent white transparent transparent;
  position: absolute;
  left: -12px;
  top: 7px;
}
.lv-tooltip > div {
  background: white;
  font-family: Lato, sans-serif;
}
.lv-tp-header {
  font-size: 12px;
  color: #7253d5;
}
.lv-tp-body {
  font-size: 12px;
  color: #707070;
}
.lv-tooltip hr {
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  margin: 4px 0;
  z-index: 1000;
}
.lv-tooltip.lv-arrow-right {
  border-left: 4px solid #7253d5;
  border-right: 0;
}
.lv-tooltip.lv-arrow-right:before {
  transform: rotate(180deg);
  left: unset;
  right: -12px;
  top: 7px;
}
.lv-tooltip.lv-arrow-up {
  border-bottom: 4px solid #7253d5;
  border-right: 0;
}
.lv-tooltip.lv-arrow-up:before {
  transform: rotate(90deg);
  top: -12px;
  left: 50%;
}
.aSt.lv-fullscreen-shrink {
  width: calc(100% - 500px) !important;
}
.switch-wrapper {
  position: relative;
  display: inline-flex;
  padding: 2px;
  border: 1px solid lightgrey;
  border-radius: 30px;
  background: white;
  text-align: center;
}
.switch-wrapper [type="radio"] {
  position: absolute;
  left: -9999px;
}
.switch-wrapper label {
  padding: 0 10px;
}
.switch-wrapper label[for="yearly"] {
  font-size: 12px;
  z-index: 1;
  min-width: 80px;
  line-height: 24px;
  cursor: pointer;
  border-radius: 30px;
  transition: color 0.25s ease-in-out;
  color: #4e4e4e;
}
.switch-wrapper label[for="yearly"] span {
  font-size: 10px;
  z-index: 1;
  line-height: 24px;
  cursor: pointer;
  border-radius: 30px;
  color: #4e4e4e;
}
.switch-wrapper label[for="monthly"] {
  font-size: 12px;
  z-index: 1;
  min-width: 80px;
  line-height: 24px;
  cursor: pointer;
  border-radius: 30px;
  transition: color 0.25s ease-in-out;
  color: #4e4e4e;
}
.switch-wrapper label:not(.highlighter):hover {
  background: #e5e5e5;
}
.switch-wrapper .highlighter {
  background: #49c0b8;
}
/*Fix for groove*/
.zp-modal {
  z-index: 9000 !important;
}
/*hubspot overwrite*/
div[data-cy="ai-mail-opener"] .zp {
  position: relative;
}
div#app-root:has(.webapp-t-closed-397) .compose-trigger-parent,
div#app-root:has(.webapp-t-minimized-396) .compose-trigger-parent,
div#app-root:has(.webapp-t-closed-397) .lv-button-container-parent,
div#app-root:has(.webapp-t-minimized-396) .lv-button-container-parent,
div#app-root:has(.webapp-t-closed-397) .lv-smart-btn,
div#app-root:has(.webapp-t-minimized-396) .lv-smart-btn {
  display: none !important;
}
div#app-root.lv-compose-container:has(.webapp-t-open-395) {
  position: relative;
}
/* Groove fixes */
div#app-root.lv-compose-container:has(.webapp-t-open-395) .lv-smart-btn {
  z-index: 3;
  right: 21px !important;
}
.webapp-t-wizardContainer-354 {
  position: relative;
}
.sentence-recommendation-modal-container,
.lv-edit-email-box-modal-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100006;
  overflow: hidden;
}
.sentence-recommendation-modal-container {
  background: #ffffffaa;
}
.lv-edit-email-box-modal-container {
  background-color: #ffffffaa;
  min-height: 500px;
}
.sentence-recommendation-modal .lv-hl-lg {
  width: 100%;
}
.sentence-recommendation-modal .lv-close,
.lv-edit-email-box-modal .lv-close {
  z-index: 2;
  position: absolute;
  right: -8px;
  top: -8px;
  width: 36px;
  height: 36px;
  font-size: 16px;
  text-align: center;
  vertical-align: center;
  line-height: 36px;
  background: url("https://extension-backend.trylavender.com/public/img/gifs/gif-close-icon.png");
  padding-top: 2px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px 12px;
  cursor: pointer;
}
.sentence-recommendation-modal,
.lv-edit-email-box-modal {
  border-radius: 8px;
  border: 2px solid #efe9e9;
  position: absolute;
  width: calc(100% - 32px);
  margin: 16px;
  top: 20px;
  z-index: 10006;
  max-height: calc(100% - 64px);
  overflow: hidden;
}
.sentence-recommendation-modal {
  background: white;
}
.lv-edit-email-box-modal {
  background-color: #faf5ff;
}
.lv-edit-email-box-header {
  color: #7353d5;
}
.sentence-recommendation-modal-header {
  border-bottom: 1px solid rgb(249, 246, 252);
}
.sentence-recommendation-modal-header > p {
  font-family: Lato, sans-serif;
  font-weight: 500;
  font-size: 11px;
  color: rgb(114, 83, 213);
  letter-spacing: 0.5px;
  margin: 4px 8px 2px;
}
.sentence-recommendation-modal-content {
  max-height: 500px;
  overflow: scroll;
}
.sentence-recommendation-modal .lv-hl-tooltip.recommendation {
  margin-top: 12px;
  box-shadow: none;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #efe9e9;
  padding-bottom: 24px;
  border-radius: 0;
}
.sentence-recommendation-modal .lv-hl-tooltip.recommendation:last-child {
  border-bottom: none;
  box-shadow: none;
}
.sentence-recommendation-modal .lv-hl-tooltip.recommendation .lv-rec-box-hide,
.sentence-recommendation-modal
  .lv-hl-tooltip.recommendation
  .lv-rec-box-refresh {
  display: none;
}
.sentence-recommendation-modal .lv-hl-rec-bottom-bar {
  display: none;
}
.sentence-recommendation-modal .lv-hl-rec {
  width: 100%;
}
.sentence-recommendation-modal .lv-row.lv-selected {
  display: none;
}
.sentence-recommendation-modal .lv-hl-rec .lv-old-txt {
  font-family: "Karla", sans-serif;
  width: 90%;
  margin: 4px auto;
  padding: 6px 8px;
  color: #4e4e4e;
  font-weight: normal;
  font-size: 11px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 4px;
  line-height: normal;
}
.sentence-recommendation-modal .lv-hl-rec .lv-rec-txt {
  margin-top: 12px;
  border-color: rgba(175, 143, 223, 0.6);
  line-height: normal;
}
.lv-old-sentence-container > p {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 11px;
  color: #7253d5;
  margin: 4px 0 2px;
  letter-spacing: 0.5px;
}
.lv-sc-highlight.green {
  line-height: normal;
}
.lv-sc-highlight.green {
  line-height: normal;
}
.footer-bar {
  bottom: 0;
  position: absolute;
  width: 100%;
}

.hidden {
  display: none;
}
