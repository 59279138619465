/** @format */

.lv-button-container-parent {
  visibility: visible !important;
  position: relative !important;
  top: 0 !important;
  width: 100% !important;
}
.lv-button-container {
  visibility: visible !important;
  opacity: 100% !important;
  flex-direction: row !important;
  width: calc(100% - 50px) !important;
  margin-left: 50px;
  height: 36px !important;
  justify-content: space-around;
  border-radius: 0 !important;
  z-index: 10005 !important;
}
.lv-button-container-parent.lv-vertical > .lv-button-container {
  margin-top: 0;
}
.lv-smart-btn {
  display: none !important;
}
.compose-trigger-parent {
  left: 0 !important;
  top: 0 !important;
  right: unset !important;
  width: 33px !important;
  height: 40px !important;
  border-radius: 0 !important;
  background-position: center !important;
  z-index: 10006 !important;
}
.LavenderNavBar {
  display: none;
}
.lv-sidebar-box,
.lv-profile-lookup-box,
.lv-bp-box {
  top: 48px !important;
  left: 0 !important;
  width: 100% !important;
  height: calc(100% - 48px) !important;
  box-shadow: none;
  position: absolute;
}
.lv-bp-box {
  background: white;
}
.lv-bullet-point-box {
  margin-top: 24px;
  /*height: calc(100% - 80px);*/
}
.lv-bullet-point-box input {
  margin-top: 0;
}
.lv-bullet-point-recipient-container {
  color: #4e4e4e;
}
.lv-bullet-point-recipient {
  width: 85% !important;
}
.lv-bullet-point-box-body > ul > li > input {
  width: 70% !important;
}
.lv-bp-menu-box {
  padding: 0;
}
.lv-bullet-point-box-body > ul > li > img {
  margin-left: 8px;
}
.lv-sidebar-box .lv-draggable {
  background: #f6f6f6;
  /*hacky way to hide*/
}
.lv-sidebar-box .lv-draggable .lv-ft-white {
  color: #f6f6f6;
  /*hacky way to hide*/
}

.lv-sidebar-container,
.lv-profile-lookup-container {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
}
.lv-sidebar-close,
.lv-profile-lookup-close {
  display: none;
}
.lv-intercom-box {
  height: 100%;
}
.gif-box {
  left: 0 !important;
  width: 100% !important;
  height: calc(100% - 48px) !important;
  top: 48px !important;
  border-radius: 0 !important;
  z-index: 10000;
  box-shadow: none;
}
.lv-draggable {
  cursor: default !important;
}
.resize-top-img {
  display: none !important;
}
.gif-header {
  border-radius: 0;
}
.gif-close {
  display: none;
}
.lv-profile-personal-header .lv-flex-fill {
  margin-top: 22px;
}
.lv-profile-personal-header {
  background-size: 100% 65% !important;
}
.lv-short-compose-box {
  left: 0 !important;
  top: 48px !important;
  width: 100% !important;
  height: calc(100% - 48px) !important;
  border-radius: 0;
  box-shadow: none;
  opacity: 100%;
}
.short-compose-top-bar {
  visibility: hidden;
}
.short-compose-container {
  height: 100%;
}
.short-compose-inner-container {
  height: 100%;
  border-radius: 0 !important;
}
.lv-subject-analysis-box {
  top: 235px !important;
  left: calc(50% - 135px) !important;
  box-shadow: 0 0 500px 200px white;
}

.lv-ea-footer {
  border-radius: 0 !important;
}
.short-compose-body {
  height: calc(100% - 46px);
}
.lv-recommendation-item {
  padding: 8px 0;
}
[data-tab="lv-ext-intercom"] {
  display: none;
}
[data-tab="lv-product-tour"] {
  display: none;
}
.lv-button-container-notification {
  display: none !important;
}
.lv-email-verification-box {
  top: 32px;
  left: -75px;
}
.lv-verification-arrow-right {
  display: none;
}
.lv-verification-hover-bridge {
  top: -10px !important;
  right: unset !important;
  left: 75px;
  height: 10px !important;
}
.background-outlook-native {
  background-size: 200px 200px;
  background-position: center;
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-image: url("https://beta.trylavender.com/public/img/loading/brand-loading.gif");
  background-color: #f0f1f5;
}
.general-flex-box {
  margin-bottom: 8px;
}
.sentence-recommendation-modal .lv-hl-rec {
  width: 100%;
}
.sentence-recommendation-modal .lv-row.lv-selected {
  display: none;
}
.sentence-recommendation-modal .lv-hl-rec .lv-old-txt {
  font-family: "Karla", sans-serif;
  width: 90%;
  margin: 4px auto;
  padding: 6px 8px;
  color: #4e4e4e;
  font-weight: normal;
  font-size: 11px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 4px;
}
.sentence-recommendation-modal .lv-hl-rec .lv-rec-txt {
  margin-top: 12px;
  border-color: rgba(175, 143, 223, 0.6);
}
.lv-bullet-point-close:not(.lv-close) {
  display: none;
}
.mobile-preview-container,
#lavender-mobile-container,
#lavender-watch-container {
  height: 828px;
  width: 377px;
  left: calc(50% - 188.5px) !important;
  top: 0 !important;
}
.mobile-preview-zoom-container {
  transform: scale(1) !important;
}
/*Mobile preview SSR fixes*/
.lavender-drag-mobile.lavender-device {
  transform: scale(1) translateX(-50%);
  left: 50% !important;
  top: 100px !important;
  /*top: 100px !important;*/
}
.lavender-drag-watch.lavender-device {
  top: 100px !important;
  /*top: 200px !important;*/
  transform: translateX(-50%);
  left: 50% !important;
}
@media only screen and (max-width: 330px), screen and (max-height: 720px) {
  .lavender-drag-mobile.lavender-device {
    transform: scale(0.7) translateX(-50%);
    left: 39% !important;
    top: 0 !important;
  }
  .lavender-drag-watch.lavender-device {
    top: 100px !important;
    transform: translateX(-50%);
    left: 50% !important;
  }
}

.lavender-move-trigger {
  visibility: hidden;
}
#lavender-mobile-container,
#lavender-watch-container {
  z-index: 10003 !important;
}
.lv-hover-mp-box {
  top: 34px !important;
  right: -25px !important;
  border-left: none !important;
  border-bottom: 4px solid #7253d5 !important;
}

.lv-chat-box {
  height: calc(100% - 48px) !important;
  top: 48px !important;
  left: 0 !important;
  width: 100% !important;
}
.lavender-device .lv-hl-underline {
  border-bottom: 0 !important;
}
.lv-hl-lg {
  width: 100%;
  max-width: 100%;
}
.lv-tone-purple.selected {
  color: white !important;
}
.lv-tooltip {
  z-index: 10100;
}
/*from global frame.css*/
.lv-switch {
  width: 32px;
  border-radius: 15px;
  height: 18px;
  background: lightgray;
  position: relative;
  cursor: pointer;
}
.lv-switch .lv-switch-circle {
  height: 14px;
  width: 14px;
  background: white;
  border-radius: 9px;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: left 0.15s;
}
.lv-switch.lv-active .lv-switch-circle {
  left: 16px;
}
.lv-switch.lv-active {
  background: #49c0b8;
}

/*INFO: for the panel to expand from right to left*/
.lv-outlook .lv-panel-root {
  height: 100vh;
  flex-direction: column;
}

/*INFO: move intercom*/
body .intercom-lightweight-app-launcher,
body .intercom-namespace .intercom-dfosxs {
  right: calc(100% - 54px);
}
.lv-white-flower-container,.lv-cog-setting-container{
  position: relative;
  left: calc(50% - 10px);
  top: calc(50% - 10px);
}
.lv-email-summary-analytics-container {
  top: 0 !important;
  left: 0;
  height: 100%;
  width: 100% !important;
  max-width: 100% !important;
  min-width: unset !important;
  border-radius: 0 !important;
}
.lv-email-thread:has(.lv-email-thread-open-email-container) .lv-email-summary-analytics-container {
  height: 50% !important;
}
/*X icon on read the room*/
.lv-email-summary-analytics-header >span{
  display: none;
}
.lv-email-thread-open-email-container{
  top: 50% !important;
  left: 0;
  height: 50% !important;
  max-height: 50% !important;
  width: 100% !important;
  max-width: 100% !important;
  min-width: unset !important;
  border-radius: 0 !important;
  display: block !important;
  z-index: 11 !important;
  animation: unset !important;
}
.lv-email-summary-analytics-header{
  border-radius: 0 !important;
}
.lv-email-summary-analytics-main{
  flex-direction: column-reverse;
}
.lv-email-summary-analytics-main > div{
  width: 100%;
  max-width: calc(100% - 48px);
}
