/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200;12..96,300;12..96,400;12..96,500;12..96,600;12..96,700;12..96,800&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200;12..96,300;12..96,400;12..96,500;12..96,600;12..96,700;12..96,800&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200;12..96,300;12..96,400;12..96,500;12..96,600;12..96,700;12..96,800&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200;12..96,300;12..96,400;12..96,500;12..96,600;12..96,700;12..96,800&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200;12..96,300;12..96,400;12..96,500;12..96,600;12..96,700;12..96,800&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200;12..96,300;12..96,400;12..96,500;12..96,600;12..96,700;12..96,800&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200;12..96,300;12..96,400;12..96,500;12..96,600;12..96,700;12..96,800&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200;12..96,300;12..96,400;12..96,500;12..96,600;12..96,700;12..96,800&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200;12..96,300;12..96,400;12..96,500;12..96,600;12..96,700;12..96,800&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200;12..96,300;12..96,400;12..96,500;12..96,600;12..96,700;12..96,800&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) {
  box-shadow: none;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-lavender {
  color: #6a2be9;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-black {
  color: #000000;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-white {
  color: #ffffff;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-pear {
  color: #caf344;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-sapphire {
  color: #2285e2;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-marigold {
  color: #fdd74c;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-peony {
  color: #ee448e;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-tart {
  color: #f05656;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-surf {
  color: #47e5b6;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-dark {
  color: #140c22;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-positive {
  color: #75c410;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-negative {
  color: #f05656;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-warning {
  color: #f6a40a;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-lavender {
  background-color: #6a2be9;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-black {
  background-color: #000000;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-white {
  background-color: #ffffff;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-pear {
  background-color: #caf344;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-sapphire {
  background-color: #2285e2;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-marigold {
  background-color: #fdd74c;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-peony {
  background-color: #ee448e;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-tart {
  background-color: #f05656;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-surf {
  background-color: #47e5b6;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-dark {
  background-color: #140c22;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-positive {
  background-color: #75c410;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-negative {
  background-color: #f05656;
}
span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar) .lav-components-badge-indicator-color-warning {
  background-color: #f6a40a;
}

span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar-size-small) .ant-badge-dot.lav-components-badge-indicator {
  border: 2px solid #f6f8fc;
  min-width: 12px;
  width: 12px;
  height: 12px;
}

span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar-size-medium) .ant-badge-dot.lav-components-badge-indicator {
  border: 2px solid #f6f8fc;
  min-width: 14px;
  width: 14px;
  height: 14px;
}

span.ant-badge.lav-components-badge:has(span.ant-avatar.lav-components-avatar-size-large) .ant-badge-dot.lav-components-badge-indicator {
  border: 3px solid #f6f8fc;
  min-width: 16px;
  width: 16px;
  height: 16px;
}

span.ant-avatar.lav-components-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}
span.ant-avatar.lav-components-avatar:not(.lav-components-avatar-src-undefined) {
  background: transparent !important;
}
span.ant-avatar.lav-components-avatar:not(.lav-components-avatar-src-undefined) img {
  object-fit: fill;
}
span.ant-avatar.lav-components-avatar span.ant-avatar-string {
  display: flex;
  align-items: center;
  justify-content: center;
}
span.ant-avatar.lav-components-avatar-size-extra-small {
  height: 24px;
  width: 24px;
}
span.ant-avatar.lav-components-avatar-size-small {
  height: 32px;
  width: 32px;
}
span.ant-avatar.lav-components-avatar-size-medium {
  height: 40px;
  width: 40px;
}
span.ant-avatar.lav-components-avatar-size-large {
  height: 48px;
  width: 48px;
}
span.ant-avatar.lav-components-avatar-size-extra-large {
  height: 64px;
  width: 64px;
}
span.ant-avatar.lav-components-avatar-color-lavender {
  background-color: #dbc5f6;
}
span.ant-avatar.lav-components-avatar-color-lavender span.lav-components-text-color-black.lav-components-text {
  color: #140c22;
}
span.ant-avatar.lav-components-avatar-color-lavender.lav-components-avatar-type-solid {
  background-color: #6a2be9;
}
span.ant-avatar.lav-components-avatar-color-lavender.lav-components-avatar-type-solid span.lav-components-text-color-black.lav-components-text {
  color: #fffdf9;
}
span.ant-avatar.lav-components-avatar-color-dark {
  background-color: #140c22;
}
span.ant-avatar.lav-components-avatar-color-dark span.lav-components-text-color-black.lav-components-text {
  color: #ffffff;
}
span.ant-avatar.lav-components-avatar-color-dark.lav-components-avatar-type-solid {
  background-color: #230a54;
}
span.ant-avatar.lav-components-avatar-color-dark.lav-components-avatar-type-solid span.lav-components-text-color-black.lav-components-text {
  color: #fffdf9;
}
span.ant-avatar.lav-components-avatar-color-pear {
  background-color: #eaff97;
}
span.ant-avatar.lav-components-avatar-color-pear span.lav-components-text-color-black.lav-components-text {
  color: #415f0c;
}
span.ant-avatar.lav-components-avatar-color-pear.lav-components-avatar-type-solid {
  background-color: #caf344;
}
span.ant-avatar.lav-components-avatar-color-pear.lav-components-avatar-type-solid span.lav-components-text-color-black.lav-components-text {
  color: #fffdf9;
}
span.ant-avatar.lav-components-avatar-color-sapphire {
  background-color: #a9d7f4;
}
span.ant-avatar.lav-components-avatar-color-sapphire span.lav-components-text-color-black.lav-components-text {
  color: #1861b9;
}
span.ant-avatar.lav-components-avatar-color-sapphire.lav-components-avatar-type-solid {
  background-color: #2285e2;
}
span.ant-avatar.lav-components-avatar-color-sapphire.lav-components-avatar-type-solid span.lav-components-text-color-black.lav-components-text {
  color: #fffdf9;
}
span.ant-avatar.lav-components-avatar-color-marigold {
  background-color: #fef2be;
}
span.ant-avatar.lav-components-avatar-color-marigold span.lav-components-text-color-black.lav-components-text {
  color: #da7c05;
}
span.ant-avatar.lav-components-avatar-color-marigold.lav-components-avatar-type-solid {
  background-color: #fdd74c;
}
span.ant-avatar.lav-components-avatar-color-marigold.lav-components-avatar-type-solid span.lav-components-text-color-black.lav-components-text {
  color: #fffdf9;
}
span.ant-avatar.lav-components-avatar-color-peony {
  background-color: #fac2e1;
}
span.ant-avatar.lav-components-avatar-color-peony span.lav-components-text-color-black.lav-components-text {
  color: #ad1049;
}
span.ant-avatar.lav-components-avatar-color-peony.lav-components-avatar-type-solid {
  background-color: #ee448e;
}
span.ant-avatar.lav-components-avatar-color-peony.lav-components-avatar-type-solid span.lav-components-text-color-black.lav-components-text {
  color: #fffdf9;
}
span.ant-avatar.lav-components-avatar-color-tart {
  background-color: #f4aea9;
}
span.ant-avatar.lav-components-avatar-color-tart span.lav-components-text-color-black.lav-components-text {
  color: #b92818;
}
span.ant-avatar.lav-components-avatar-color-tart.lav-components-avatar-type-solid {
  background-color: #f05656;
}
span.ant-avatar.lav-components-avatar-color-tart.lav-components-avatar-type-solid span.lav-components-text-color-black.lav-components-text {
  color: #fffdf9;
}
span.ant-avatar.lav-components-avatar-color-white {
  background-color: #ffffff;
}
span.ant-avatar.lav-components-avatar-color-white span.lav-components-text-color-black.lav-components-text {
  color: #140c22;
}
span.ant-avatar.lav-components-avatar-color-neutral-100 {
  background-color: #ececee;
}
span.ant-avatar.lav-components-avatar-color-neutral-100 span.lav-components-text-color-black.lav-components-text {
  color: #140c22;
}
span.ant-avatar.lav-components-avatar-color-neutral-200 {
  background-color: #e1e0e4;
}
span.ant-avatar.lav-components-avatar-color-neutral-200 span.lav-components-text-color-black.lav-components-text {
  color: #140c22;
}
span.ant-avatar.lav-components-avatar-color-neutral-disabled {
  background-color: #ececee;
}
span.ant-avatar.lav-components-avatar-color-neutral-disabled span.lav-components-text-color-black.lav-components-text {
  color: #b4b1bc;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* https://fonts.google.com/specimen/Bricolage+Grotesque */
/* https://fonts.google.com/specimen/Inter */
span.lav-components-text {
  color: #000000;
}
span.lav-components-text-underline-true {
  text-decoration: underline;
}
span.lav-components-text-color-lavender {
  color: #6a2be9;
}
span.lav-components-text-color-black {
  color: #000000;
}
span.lav-components-text-color-white {
  color: #ffffff;
}
span.lav-components-text-color-pear {
  color: #caf344;
}
span.lav-components-text-color-sapphire {
  color: #2285e2;
}
span.lav-components-text-color-marigold {
  color: #fdd74c;
}
span.lav-components-text-color-peony {
  color: #ee448e;
}
span.lav-components-text-color-tart {
  color: #f05656;
}
span.lav-components-text-color-surf {
  color: #47e5b6;
}
span.lav-components-text-color-dark {
  color: #140c22;
}
span.lav-components-text-color-positive {
  color: #75c410;
}
span.lav-components-text-color-negative {
  color: #f05656;
}
span.lav-components-text-color-warning {
  color: #f6a40a;
}
span.lav-components-text-size-body1 {
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 18px;
  line-height: 18px; /* 150% */
  margin: -4.5px 0;
}
span.lav-components-text-size-body2 {
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 21px;
  line-height: 21px; /* 150% */
  margin: -5.5px 0;
}
span.lav-components-text-size-body3 {
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 24px;
  line-height: 24px; /* 150% */
  margin: -6px 0;
}
span.lav-components-text-size-subtext1 {
  font-size: 12px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 18px;
  margin: -5px 0;
  line-height: 18px; /* 150% */
  font-weight: 500;
  letter-spacing: normal;
}
span.lav-components-text-size-subtext2 {
  font-size: 14px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 21px;
  margin: -6px 0;
  line-height: 21px; /* 150% */
}
span.lav-components-text-size-subtext3 {
  font-size: 16px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 24px;
  margin: -6.5px 0;
  line-height: 24px; /* 150% */
}
span.lav-components-text-size-subtext4 {
  font-size: 20px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 30px;
  margin: -5.5px 0;
  line-height: 24px; /* 120% */
}
span.lav-components-text-size-subtext5 {
  font-size: 24px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 36px;
  margin: -6px 0;
  line-height: 28px; /* 116.667% */
}
span.lav-components-text-size-header1 {
  font-size: 36px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 54px;
  margin: -12.5px 0;
  line-height: 48px; /* 133.333% */
}
span.lav-components-text-size-header2 {
  font-size: 40px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 60px;
  margin: -15px 0;
  line-height: 56px; /* 140% */
}
span.lav-components-text-size-header3 {
  font-size: 48px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 72px;
  margin: -20px 0;
  line-height: 72px; /* 150% */
}

div.lav-components-input-container {
  width: 100%;
}

input.ant-input.lav-components-input {
  display: flex;
  align-items: center;
  border: 1px solid #e1e0e4;
  border-radius: 8px !important;
  background: #ffffff;
  padding: 0 4px 0 12px;
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 21px;
}
input.ant-input.lav-components-input-size-small {
  height: 32px;
  font-size: 12px;
  padding: 0 10px;
}
input.ant-input.lav-components-input-size-medium {
  height: 40px;
  font-size: 14px;
}
input.ant-input.lav-components-input-state-disabled {
  color: #b4b1bc;
  border: 1px solid #ececee;
  background: #f8f7f8;
  opacity: 0.4;
}
input.ant-input.lav-components-input-state-error {
  border: 2px solid #f05656;
}
input.ant-input.lav-components-input:hover {
  border: 2px solid #d6d4da;
}
input.ant-input.lav-components-input:focus {
  border: 1px solid #9261ec;
  box-shadow: 0px 0px 0px 2px #dbc5f6;
}

div.lav-components-input-error-text {
  display: flex;
  align-items: center;
  color: #f05656;
  margin-top: 8px;
}
div.lav-components-input-error-text span:nth-child(2) {
  margin-left: 8px;
  overflow: hidden;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* https://fonts.google.com/specimen/Bricolage+Grotesque */
/* https://fonts.google.com/specimen/Inter */
span.lav-components-text {
  color: #000000;
}
span.lav-components-text-underline-true {
  text-decoration: underline;
}
span.lav-components-text-color-lavender {
  color: #6a2be9;
}
span.lav-components-text-color-black {
  color: #000000;
}
span.lav-components-text-color-white {
  color: #ffffff;
}
span.lav-components-text-color-pear {
  color: #caf344;
}
span.lav-components-text-color-sapphire {
  color: #2285e2;
}
span.lav-components-text-color-marigold {
  color: #fdd74c;
}
span.lav-components-text-color-peony {
  color: #ee448e;
}
span.lav-components-text-color-tart {
  color: #f05656;
}
span.lav-components-text-color-surf {
  color: #47e5b6;
}
span.lav-components-text-color-dark {
  color: #140c22;
}
span.lav-components-text-color-positive {
  color: #75c410;
}
span.lav-components-text-color-negative {
  color: #f05656;
}
span.lav-components-text-color-warning {
  color: #f6a40a;
}
span.lav-components-text-size-body1 {
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 18px;
  line-height: 18px; /* 150% */
  margin: -4.5px 0;
}
span.lav-components-text-size-body2 {
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 21px;
  line-height: 21px; /* 150% */
  margin: -5.5px 0;
}
span.lav-components-text-size-body3 {
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 24px;
  line-height: 24px; /* 150% */
  margin: -6px 0;
}
span.lav-components-text-size-subtext1 {
  font-size: 12px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 18px;
  margin: -5px 0;
  line-height: 18px; /* 150% */
  font-weight: 500;
  letter-spacing: normal;
}
span.lav-components-text-size-subtext2 {
  font-size: 14px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 21px;
  margin: -6px 0;
  line-height: 21px; /* 150% */
}
span.lav-components-text-size-subtext3 {
  font-size: 16px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 24px;
  margin: -6.5px 0;
  line-height: 24px; /* 150% */
}
span.lav-components-text-size-subtext4 {
  font-size: 20px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 30px;
  margin: -5.5px 0;
  line-height: 24px; /* 120% */
}
span.lav-components-text-size-subtext5 {
  font-size: 24px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 36px;
  margin: -6px 0;
  line-height: 28px; /* 116.667% */
}
span.lav-components-text-size-header1 {
  font-size: 36px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 54px;
  margin: -12.5px 0;
  line-height: 48px; /* 133.333% */
}
span.lav-components-text-size-header2 {
  font-size: 40px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 60px;
  margin: -15px 0;
  line-height: 56px; /* 140% */
}
span.lav-components-text-size-header3 {
  font-size: 48px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 72px;
  margin: -20px 0;
  line-height: 72px; /* 150% */
}

ul.ant-menu-vertical.ant-menu.lav-components-menu {
  border-inline-end: none;
}
ul.ant-menu-vertical.ant-menu.lav-components-menu li.ant-menu-item.lav-components-menu-item {
  width: calc(100% - 4px);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: #000000;
  border-radius: 4px;
  padding: 8px;
  height: auto;
  line-height: 16px;
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 18px;
}
ul.ant-menu-vertical.ant-menu.lav-components-menu li.ant-menu-item.lav-components-menu-item span.ant-menu-title-content {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  text-wrap: wrap;
}
ul.ant-menu-vertical.ant-menu.lav-components-menu li.ant-menu-item.lav-components-menu-item span.ant-menu-title-content .lav-components-menu-item-inner {
  display: flex;
  flex-direction: column;
}
ul.ant-menu-vertical.ant-menu.lav-components-menu li.ant-menu-item.lav-components-menu-item span.ant-menu-title-content .lav-components-menu-item-inner .lav-components-menu-item-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  width: 100%;
  align-items: start;
}
ul.ant-menu-vertical.ant-menu.lav-components-menu li.ant-menu-item.lav-components-menu-item span.ant-menu-title-content .lav-components-menu-item-inner .lav-components-menu-item-content {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.25px;
  color: #858092;
}
ul.ant-menu-vertical.ant-menu.lav-components-menu li.ant-menu-item.lav-components-menu-item:hover {
  background-color: #ececee;
}
ul.ant-menu-vertical.ant-menu.lav-components-menu li.ant-menu-item.lav-components-menu-item.ant-menu-item-selected:not(.ant-menu-item-danger) {
  background-color: #ece2f9;
}
ul.ant-menu-vertical.ant-menu.lav-components-menu li.ant-menu-item.lav-components-menu-item.ant-menu-item-selected:not(.ant-menu-item-danger) svg {
  color: #6a2be9;
}
ul.ant-menu-vertical.ant-menu.lav-components-menu li.ant-menu-item.lav-components-menu-item.ant-menu-item-selected:not(.ant-menu-item-danger):hover {
  background-color: #dbc5f6;
}
ul.ant-menu-vertical.ant-menu.lav-components-menu li.ant-menu-item.lav-components-menu-item.ant-menu-item-danger {
  color: #b92818;
  background-color: #f4aea9;
}
ul.ant-menu-vertical.ant-menu.lav-components-menu li.ant-menu-item.lav-components-menu-item.ant-menu-item-danger:hover {
  color: #b92818;
  background-color: #fadad6;
}
ul.ant-menu-vertical.ant-menu.lav-components-menu li.ant-menu-item.lav-components-menu-item.lav-components-menu-item-state-disabled .lav-components-menu-item-inner span.lav-components-menu-item-content,
ul.ant-menu-vertical.ant-menu.lav-components-menu li.ant-menu-item.lav-components-menu-item.lav-components-menu-item-state-disabled .lav-components-menu-item-inner span.lav-components-menu-item-title {
  color: #b4b1bc;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

button.ant-btn.lav-components-button,
a.ant-btn.lav-components-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
}
button.ant-btn.lav-components-button span,
a.ant-btn.lav-components-button span {
  display: flex;
}
button.ant-btn.lav-components-button .ant-btn-loading-icon,
a.ant-btn.lav-components-button .ant-btn-loading-icon {
  margin-right: 8px !important;
}
button.ant-btn.lav-components-button-size-small.ant-btn-sm,
a.ant-btn.lav-components-button-size-small.ant-btn-sm {
  min-width: 24px;
  font-size: 12px;
  padding: 0 10px;
}
button.ant-btn.lav-components-button-size-medium,
a.ant-btn.lav-components-button-size-medium {
  min-width: 32px;
  font-size: 14px;
  padding: 0 12px;
}
button.ant-btn.lav-components-button-size-large.ant-btn-lg,
a.ant-btn.lav-components-button-size-large.ant-btn-lg {
  min-width: 40px;
  font-size: 16px;
  padding: 0 16px;
}
button.ant-btn.lav-components-button:not(.lav-components-button-icon-undefined),
a.ant-btn.lav-components-button:not(.lav-components-button-icon-undefined) {
  padding: 0;
}
button.ant-btn.lav-components-button-fullWidth-true,
a.ant-btn.lav-components-button-fullWidth-true {
  width: 100%;
}
button.ant-btn.lav-components-button-spaceBetween-true,
a.ant-btn.lav-components-button-spaceBetween-true {
  justify-content: space-between;
}
button.ant-btn.lav-components-button-radius-none,
a.ant-btn.lav-components-button-radius-none {
  border-radius: 0;
}
button.ant-btn.lav-components-button-radius-sm,
a.ant-btn.lav-components-button-radius-sm {
  border-radius: 4px;
}
button.ant-btn.lav-components-button-radius-md,
a.ant-btn.lav-components-button-radius-md {
  border-radius: 8px;
}
button.ant-btn.lav-components-button-radius-xl,
a.ant-btn.lav-components-button-radius-xl {
  border-radius: 32px;
}
button.ant-btn.lav-components-button-variant-default,
a.ant-btn.lav-components-button-variant-default {
  border: 1px solid #e1e0e4;
  background-color: #ffffff;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
}
button.ant-btn.lav-components-button-variant-default span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-default span.lav-components-text-color-black.lav-components-text {
  color: #140c22 !important;
}
button.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled).lav-components-button-state-hover, button.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled):hover,
a.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled).lav-components-button-state-hover,
a.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled):hover {
  border: 1px solid #d6d4da !important;
  background-color: #ececee !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled).lav-components-button-state-pressed,
a.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled).lav-components-button-state-pressed {
  border: 1px solid #d6d4da !important;
  background-color: #d6d4da !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled).lav-components-button-state-focus,
button.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled) :focus, button.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled):active,
a.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled).lav-components-button-state-focus,
a.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled) :focus,
a.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled):active {
  border: 1px solid #ffffff;
  background-color: #ece2f9;
  box-shadow: 0px 0px 0px 2px #6a2be9;
}
button.ant-btn.lav-components-button-variant-default.lav-components-button-state-disabled, button.ant-btn.lav-components-button-variant-default:disabled, button.ant-btn.lav-components-button-variant-default.ant-btn-disabled,
a.ant-btn.lav-components-button-variant-default.lav-components-button-state-disabled,
a.ant-btn.lav-components-button-variant-default:disabled,
a.ant-btn.lav-components-button-variant-default.ant-btn-disabled {
  border: 1px solid #ececee !important;
  background-color: #f8f7f8 !important;
  opacity: 0.4;
}
button.ant-btn.lav-components-button-variant-default.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-default:disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-default.ant-btn-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-default.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-default:disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-default.ant-btn-disabled span.lav-components-text-color-black.lav-components-text {
  color: #b4b1bc !important;
}
button.ant-btn.lav-components-button-variant-default.lav-components-button-state-loading,
a.ant-btn.lav-components-button-variant-default.lav-components-button-state-loading {
  opacity: 0.64 !important;
  padding-left: 12px;
}
button.ant-btn.lav-components-button-variant-default.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon,
a.ant-btn.lav-components-button-variant-default.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon {
  margin-inline-end: 0;
}
button.ant-btn.lav-components-button-variant-primary,
a.ant-btn.lav-components-button-variant-primary {
  border: 1px solid #6a2be9;
  background-color: #6a2be9;
}
button.ant-btn.lav-components-button-variant-primary span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-primary span.lav-components-text-color-black.lav-components-text {
  color: #ffffff !important;
}
button.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled).lav-components-button-state-hover, button.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled):hover,
a.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled).lav-components-button-state-hover,
a.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled):hover {
  border: 1px solid #9261ec !important;
  background-color: #9261ec !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled).lav-components-button-state-pressed,
a.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled).lav-components-button-state-pressed {
  border: 1px solid #5318cc !important;
  background-color: #5318cc !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled).lav-components-button-state-focus,
button.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled) :focus, button.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled):active,
a.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled).lav-components-button-state-focus,
a.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled) :focus,
a.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled):active {
  border: 1px solid #ffffff;
  background-color: #6a2be9;
  box-shadow: 0px 0px 0px 2px #6a2be9;
}
button.ant-btn.lav-components-button-variant-primary.lav-components-button-state-disabled, button.ant-btn.lav-components-button-variant-primary:disabled, button.ant-btn.lav-components-button-variant-primary.ant-btn-disabled,
a.ant-btn.lav-components-button-variant-primary.lav-components-button-state-disabled,
a.ant-btn.lav-components-button-variant-primary:disabled,
a.ant-btn.lav-components-button-variant-primary.ant-btn-disabled {
  border: 1px solid #6a2be9 !important;
  background-color: #6a2be9 !important;
  opacity: 0.4;
}
button.ant-btn.lav-components-button-variant-primary.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-primary:disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-primary.ant-btn-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-primary.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-primary:disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-primary.ant-btn-disabled span.lav-components-text-color-black.lav-components-text {
  color: #ffffff !important;
}
button.ant-btn.lav-components-button-variant-primary.lav-components-button-state-loading,
a.ant-btn.lav-components-button-variant-primary.lav-components-button-state-loading {
  opacity: 0.64 !important;
  padding-left: 12px;
}
button.ant-btn.lav-components-button-variant-primary.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon,
a.ant-btn.lav-components-button-variant-primary.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon {
  margin-inline-end: 0;
}
button.ant-btn.lav-components-button-variant-secondary,
a.ant-btn.lav-components-button-variant-secondary {
  border: 1px solid #ececee;
  background-color: #ececee;
}
button.ant-btn.lav-components-button-variant-secondary span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-secondary span.lav-components-text-color-black.lav-components-text {
  color: #140c22 !important;
}
button.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled).lav-components-button-state-hover, button.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled):hover,
a.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled).lav-components-button-state-hover,
a.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled):hover {
  border: 1px solid #e1e0e4 !important;
  background-color: #e1e0e4 !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled).lav-components-button-state-pressed,
a.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled).lav-components-button-state-pressed {
  border: 1px solid #d6d4da !important;
  background-color: #d6d4da !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled).lav-components-button-state-focus,
button.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled) :focus, button.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled):active,
a.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled).lav-components-button-state-focus,
a.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled) :focus,
a.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled):active {
  border: 1px solid #ffffff;
  background-color: #e1e0e4;
  box-shadow: 0px 0px 0px 2px #6a2be9;
}
button.ant-btn.lav-components-button-variant-secondary.lav-components-button-state-disabled, button.ant-btn.lav-components-button-variant-secondary:disabled, button.ant-btn.lav-components-button-variant-secondary.ant-btn-disabled,
a.ant-btn.lav-components-button-variant-secondary.lav-components-button-state-disabled,
a.ant-btn.lav-components-button-variant-secondary:disabled,
a.ant-btn.lav-components-button-variant-secondary.ant-btn-disabled {
  border: 1px solid #ececee !important;
  background-color: #f8f7f8 !important;
  opacity: 0.4;
}
button.ant-btn.lav-components-button-variant-secondary.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-secondary:disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-secondary.ant-btn-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-secondary.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-secondary:disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-secondary.ant-btn-disabled span.lav-components-text-color-black.lav-components-text {
  color: #b4b1bc !important;
}
button.ant-btn.lav-components-button-variant-secondary.lav-components-button-state-loading,
a.ant-btn.lav-components-button-variant-secondary.lav-components-button-state-loading {
  opacity: 0.64 !important;
  padding-left: 12px;
}
button.ant-btn.lav-components-button-variant-secondary.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon,
a.ant-btn.lav-components-button-variant-secondary.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon {
  margin-inline-end: 0;
}
button.ant-btn.lav-components-button-variant-plain span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-plain span.lav-components-text-color-black.lav-components-text {
  color: #140c22 !important;
}
button.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled).lav-components-button-state-hover, button.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled):hover,
a.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled).lav-components-button-state-hover,
a.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled):hover {
  border: 1px solid #ececee !important;
  background-color: #ececee !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled).lav-components-button-state-pressed,
a.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled).lav-components-button-state-pressed {
  border: 1px solid #d6d4da !important;
  background-color: #d6d4da !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled).lav-components-button-state-focus,
button.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled) :focus, button.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled):active,
a.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled).lav-components-button-state-focus,
a.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled) :focus,
a.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled):active {
  border: 1px solid #ffffff;
  background-color: #ece2f9;
  box-shadow: 0px 0px 0px 2px #6a2be9;
}
button.ant-btn.lav-components-button-variant-plain.lav-components-button-state-disabled, button.ant-btn.lav-components-button-variant-plain:disabled, button.ant-btn.lav-components-button-variant-plain.ant-btn-disabled,
a.ant-btn.lav-components-button-variant-plain.lav-components-button-state-disabled,
a.ant-btn.lav-components-button-variant-plain:disabled,
a.ant-btn.lav-components-button-variant-plain.ant-btn-disabled {
  border: none !important;
  background-color: transparent !important;
  opacity: 0.4;
}
button.ant-btn.lav-components-button-variant-plain.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-plain:disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-plain.ant-btn-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-plain.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-plain:disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-plain.ant-btn-disabled span.lav-components-text-color-black.lav-components-text {
  color: #b4b1bc !important;
}
button.ant-btn.lav-components-button-variant-plain.lav-components-button-state-loading,
a.ant-btn.lav-components-button-variant-plain.lav-components-button-state-loading {
  opacity: 0.64 !important;
  padding-left: 12px;
}
button.ant-btn.lav-components-button-variant-plain.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon,
a.ant-btn.lav-components-button-variant-plain.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon {
  margin-inline-end: 0;
}
button.ant-btn.lav-components-button-variant-danger,
a.ant-btn.lav-components-button-variant-danger {
  border: none;
  background-color: #fde8f4;
}
button.ant-btn.lav-components-button-variant-danger span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-danger span.lav-components-text-color-black.lav-components-text {
  color: #ad1049 !important;
}
button.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled).lav-components-button-state-hover, button.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled):hover,
a.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled).lav-components-button-state-hover,
a.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled):hover {
  border: none !important;
  background-color: #fac2e1 !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled).lav-components-button-state-pressed,
a.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled).lav-components-button-state-pressed {
  border: none !important;
  background-color: #f79cc9 !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled).lav-components-button-state-focus,
button.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled) :focus, button.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled):active,
a.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled).lav-components-button-state-focus,
a.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled) :focus,
a.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled):active {
  border: 1px solid #ffffff;
  background-color: #fde8f4;
  box-shadow: 0px 0px 0px 2px #ad1049;
}
button.ant-btn.lav-components-button-variant-danger.lav-components-button-state-disabled, button.ant-btn.lav-components-button-variant-danger:disabled, button.ant-btn.lav-components-button-variant-danger.ant-btn-disabled,
a.ant-btn.lav-components-button-variant-danger.lav-components-button-state-disabled,
a.ant-btn.lav-components-button-variant-danger:disabled,
a.ant-btn.lav-components-button-variant-danger.ant-btn-disabled {
  border: 1px solid #ececee !important;
  background-color: #f8f7f8 !important;
  opacity: 0.4;
}
button.ant-btn.lav-components-button-variant-danger.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-danger:disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-danger.ant-btn-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-danger.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-danger:disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-danger.ant-btn-disabled span.lav-components-text-color-black.lav-components-text {
  color: #b4b1bc !important;
}
button.ant-btn.lav-components-button-variant-danger.lav-components-button-state-loading,
a.ant-btn.lav-components-button-variant-danger.lav-components-button-state-loading {
  opacity: 0.64 !important;
  padding-left: 12px;
}
button.ant-btn.lav-components-button-variant-danger.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon,
a.ant-btn.lav-components-button-variant-danger.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon {
  margin-inline-end: 0;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

button.ant-btn.lav-components-button,
a.ant-btn.lav-components-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
}
button.ant-btn.lav-components-button span,
a.ant-btn.lav-components-button span {
  display: flex;
}
button.ant-btn.lav-components-button .ant-btn-loading-icon,
a.ant-btn.lav-components-button .ant-btn-loading-icon {
  margin-right: 8px !important;
}
button.ant-btn.lav-components-button-size-small.ant-btn-sm,
a.ant-btn.lav-components-button-size-small.ant-btn-sm {
  min-width: 24px;
  font-size: 12px;
  padding: 0 10px;
}
button.ant-btn.lav-components-button-size-medium,
a.ant-btn.lav-components-button-size-medium {
  min-width: 32px;
  font-size: 14px;
  padding: 0 12px;
}
button.ant-btn.lav-components-button-size-large.ant-btn-lg,
a.ant-btn.lav-components-button-size-large.ant-btn-lg {
  min-width: 40px;
  font-size: 16px;
  padding: 0 16px;
}
button.ant-btn.lav-components-button:not(.lav-components-button-icon-undefined),
a.ant-btn.lav-components-button:not(.lav-components-button-icon-undefined) {
  padding: 0;
}
button.ant-btn.lav-components-button-fullWidth-true,
a.ant-btn.lav-components-button-fullWidth-true {
  width: 100%;
}
button.ant-btn.lav-components-button-spaceBetween-true,
a.ant-btn.lav-components-button-spaceBetween-true {
  justify-content: space-between;
}
button.ant-btn.lav-components-button-radius-none,
a.ant-btn.lav-components-button-radius-none {
  border-radius: 0;
}
button.ant-btn.lav-components-button-radius-sm,
a.ant-btn.lav-components-button-radius-sm {
  border-radius: 4px;
}
button.ant-btn.lav-components-button-radius-md,
a.ant-btn.lav-components-button-radius-md {
  border-radius: 8px;
}
button.ant-btn.lav-components-button-radius-xl,
a.ant-btn.lav-components-button-radius-xl {
  border-radius: 32px;
}
button.ant-btn.lav-components-button-variant-default,
a.ant-btn.lav-components-button-variant-default {
  border: 1px solid #e1e0e4;
  background-color: #ffffff;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
}
button.ant-btn.lav-components-button-variant-default span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-default span.lav-components-text-color-black.lav-components-text {
  color: #140c22 !important;
}
button.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled).lav-components-button-state-hover, button.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled):hover,
a.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled).lav-components-button-state-hover,
a.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled):hover {
  border: 1px solid #d6d4da !important;
  background-color: #ececee !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled).lav-components-button-state-pressed,
a.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled).lav-components-button-state-pressed {
  border: 1px solid #d6d4da !important;
  background-color: #d6d4da !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled).lav-components-button-state-focus,
button.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled) :focus, button.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled):active,
a.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled).lav-components-button-state-focus,
a.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled) :focus,
a.ant-btn.lav-components-button-variant-default:not(.ant-btn-disabled):active {
  border: 1px solid #ffffff;
  background-color: #ece2f9;
  box-shadow: 0px 0px 0px 2px #6a2be9;
}
button.ant-btn.lav-components-button-variant-default.lav-components-button-state-disabled, button.ant-btn.lav-components-button-variant-default:disabled, button.ant-btn.lav-components-button-variant-default.ant-btn-disabled,
a.ant-btn.lav-components-button-variant-default.lav-components-button-state-disabled,
a.ant-btn.lav-components-button-variant-default:disabled,
a.ant-btn.lav-components-button-variant-default.ant-btn-disabled {
  border: 1px solid #ececee !important;
  background-color: #f8f7f8 !important;
  opacity: 0.4;
}
button.ant-btn.lav-components-button-variant-default.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-default:disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-default.ant-btn-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-default.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-default:disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-default.ant-btn-disabled span.lav-components-text-color-black.lav-components-text {
  color: #b4b1bc !important;
}
button.ant-btn.lav-components-button-variant-default.lav-components-button-state-loading,
a.ant-btn.lav-components-button-variant-default.lav-components-button-state-loading {
  opacity: 0.64 !important;
  padding-left: 12px;
}
button.ant-btn.lav-components-button-variant-default.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon,
a.ant-btn.lav-components-button-variant-default.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon {
  margin-inline-end: 0;
}
button.ant-btn.lav-components-button-variant-primary,
a.ant-btn.lav-components-button-variant-primary {
  border: 1px solid #6a2be9;
  background-color: #6a2be9;
}
button.ant-btn.lav-components-button-variant-primary span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-primary span.lav-components-text-color-black.lav-components-text {
  color: #ffffff !important;
}
button.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled).lav-components-button-state-hover, button.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled):hover,
a.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled).lav-components-button-state-hover,
a.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled):hover {
  border: 1px solid #9261ec !important;
  background-color: #9261ec !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled).lav-components-button-state-pressed,
a.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled).lav-components-button-state-pressed {
  border: 1px solid #5318cc !important;
  background-color: #5318cc !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled).lav-components-button-state-focus,
button.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled) :focus, button.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled):active,
a.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled).lav-components-button-state-focus,
a.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled) :focus,
a.ant-btn.lav-components-button-variant-primary:not(.ant-btn-disabled):active {
  border: 1px solid #ffffff;
  background-color: #6a2be9;
  box-shadow: 0px 0px 0px 2px #6a2be9;
}
button.ant-btn.lav-components-button-variant-primary.lav-components-button-state-disabled, button.ant-btn.lav-components-button-variant-primary:disabled, button.ant-btn.lav-components-button-variant-primary.ant-btn-disabled,
a.ant-btn.lav-components-button-variant-primary.lav-components-button-state-disabled,
a.ant-btn.lav-components-button-variant-primary:disabled,
a.ant-btn.lav-components-button-variant-primary.ant-btn-disabled {
  border: 1px solid #6a2be9 !important;
  background-color: #6a2be9 !important;
  opacity: 0.4;
}
button.ant-btn.lav-components-button-variant-primary.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-primary:disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-primary.ant-btn-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-primary.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-primary:disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-primary.ant-btn-disabled span.lav-components-text-color-black.lav-components-text {
  color: #ffffff !important;
}
button.ant-btn.lav-components-button-variant-primary.lav-components-button-state-loading,
a.ant-btn.lav-components-button-variant-primary.lav-components-button-state-loading {
  opacity: 0.64 !important;
  padding-left: 12px;
}
button.ant-btn.lav-components-button-variant-primary.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon,
a.ant-btn.lav-components-button-variant-primary.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon {
  margin-inline-end: 0;
}
button.ant-btn.lav-components-button-variant-secondary,
a.ant-btn.lav-components-button-variant-secondary {
  border: 1px solid #ececee;
  background-color: #ececee;
}
button.ant-btn.lav-components-button-variant-secondary span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-secondary span.lav-components-text-color-black.lav-components-text {
  color: #140c22 !important;
}
button.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled).lav-components-button-state-hover, button.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled):hover,
a.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled).lav-components-button-state-hover,
a.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled):hover {
  border: 1px solid #e1e0e4 !important;
  background-color: #e1e0e4 !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled).lav-components-button-state-pressed,
a.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled).lav-components-button-state-pressed {
  border: 1px solid #d6d4da !important;
  background-color: #d6d4da !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled).lav-components-button-state-focus,
button.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled) :focus, button.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled):active,
a.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled).lav-components-button-state-focus,
a.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled) :focus,
a.ant-btn.lav-components-button-variant-secondary:not(.ant-btn-disabled):active {
  border: 1px solid #ffffff;
  background-color: #e1e0e4;
  box-shadow: 0px 0px 0px 2px #6a2be9;
}
button.ant-btn.lav-components-button-variant-secondary.lav-components-button-state-disabled, button.ant-btn.lav-components-button-variant-secondary:disabled, button.ant-btn.lav-components-button-variant-secondary.ant-btn-disabled,
a.ant-btn.lav-components-button-variant-secondary.lav-components-button-state-disabled,
a.ant-btn.lav-components-button-variant-secondary:disabled,
a.ant-btn.lav-components-button-variant-secondary.ant-btn-disabled {
  border: 1px solid #ececee !important;
  background-color: #f8f7f8 !important;
  opacity: 0.4;
}
button.ant-btn.lav-components-button-variant-secondary.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-secondary:disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-secondary.ant-btn-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-secondary.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-secondary:disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-secondary.ant-btn-disabled span.lav-components-text-color-black.lav-components-text {
  color: #b4b1bc !important;
}
button.ant-btn.lav-components-button-variant-secondary.lav-components-button-state-loading,
a.ant-btn.lav-components-button-variant-secondary.lav-components-button-state-loading {
  opacity: 0.64 !important;
  padding-left: 12px;
}
button.ant-btn.lav-components-button-variant-secondary.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon,
a.ant-btn.lav-components-button-variant-secondary.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon {
  margin-inline-end: 0;
}
button.ant-btn.lav-components-button-variant-plain span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-plain span.lav-components-text-color-black.lav-components-text {
  color: #140c22 !important;
}
button.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled).lav-components-button-state-hover, button.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled):hover,
a.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled).lav-components-button-state-hover,
a.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled):hover {
  border: 1px solid #ececee !important;
  background-color: #ececee !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled).lav-components-button-state-pressed,
a.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled).lav-components-button-state-pressed {
  border: 1px solid #d6d4da !important;
  background-color: #d6d4da !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled).lav-components-button-state-focus,
button.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled) :focus, button.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled):active,
a.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled).lav-components-button-state-focus,
a.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled) :focus,
a.ant-btn.lav-components-button-variant-plain:not(.ant-btn-disabled):active {
  border: 1px solid #ffffff;
  background-color: #ece2f9;
  box-shadow: 0px 0px 0px 2px #6a2be9;
}
button.ant-btn.lav-components-button-variant-plain.lav-components-button-state-disabled, button.ant-btn.lav-components-button-variant-plain:disabled, button.ant-btn.lav-components-button-variant-plain.ant-btn-disabled,
a.ant-btn.lav-components-button-variant-plain.lav-components-button-state-disabled,
a.ant-btn.lav-components-button-variant-plain:disabled,
a.ant-btn.lav-components-button-variant-plain.ant-btn-disabled {
  border: none !important;
  background-color: transparent !important;
  opacity: 0.4;
}
button.ant-btn.lav-components-button-variant-plain.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-plain:disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-plain.ant-btn-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-plain.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-plain:disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-plain.ant-btn-disabled span.lav-components-text-color-black.lav-components-text {
  color: #b4b1bc !important;
}
button.ant-btn.lav-components-button-variant-plain.lav-components-button-state-loading,
a.ant-btn.lav-components-button-variant-plain.lav-components-button-state-loading {
  opacity: 0.64 !important;
  padding-left: 12px;
}
button.ant-btn.lav-components-button-variant-plain.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon,
a.ant-btn.lav-components-button-variant-plain.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon {
  margin-inline-end: 0;
}
button.ant-btn.lav-components-button-variant-danger,
a.ant-btn.lav-components-button-variant-danger {
  border: none;
  background-color: #fde8f4;
}
button.ant-btn.lav-components-button-variant-danger span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-danger span.lav-components-text-color-black.lav-components-text {
  color: #ad1049 !important;
}
button.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled).lav-components-button-state-hover, button.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled):hover,
a.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled).lav-components-button-state-hover,
a.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled):hover {
  border: none !important;
  background-color: #fac2e1 !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled).lav-components-button-state-pressed,
a.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled).lav-components-button-state-pressed {
  border: none !important;
  background-color: #f79cc9 !important;
  box-shadow: none !important;
}
button.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled).lav-components-button-state-focus,
button.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled) :focus, button.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled):active,
a.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled).lav-components-button-state-focus,
a.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled) :focus,
a.ant-btn.lav-components-button-variant-danger:not(.ant-btn-disabled):active {
  border: 1px solid #ffffff;
  background-color: #fde8f4;
  box-shadow: 0px 0px 0px 2px #ad1049;
}
button.ant-btn.lav-components-button-variant-danger.lav-components-button-state-disabled, button.ant-btn.lav-components-button-variant-danger:disabled, button.ant-btn.lav-components-button-variant-danger.ant-btn-disabled,
a.ant-btn.lav-components-button-variant-danger.lav-components-button-state-disabled,
a.ant-btn.lav-components-button-variant-danger:disabled,
a.ant-btn.lav-components-button-variant-danger.ant-btn-disabled {
  border: 1px solid #ececee !important;
  background-color: #f8f7f8 !important;
  opacity: 0.4;
}
button.ant-btn.lav-components-button-variant-danger.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-danger:disabled span.lav-components-text-color-black.lav-components-text, button.ant-btn.lav-components-button-variant-danger.ant-btn-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-danger.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-danger:disabled span.lav-components-text-color-black.lav-components-text,
a.ant-btn.lav-components-button-variant-danger.ant-btn-disabled span.lav-components-text-color-black.lav-components-text {
  color: #b4b1bc !important;
}
button.ant-btn.lav-components-button-variant-danger.lav-components-button-state-loading,
a.ant-btn.lav-components-button-variant-danger.lav-components-button-state-loading {
  opacity: 0.64 !important;
  padding-left: 12px;
}
button.ant-btn.lav-components-button-variant-danger.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon,
a.ant-btn.lav-components-button-variant-danger.lav-components-button-state-loading .ant-btn-icon.ant-btn-loading-icon {
  margin-inline-end: 0;
}

div.lav-components-dropdown-container-variant-default {
  border-radius: 8px;
  border: 1px solid #d6d4da;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
}

button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown.lav-components-button-state-disabled, button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown:disabled, button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown.ant-btn-disabled {
  border: 1px solid 1px solid #ececee !important;
  background-color: #f8f7f8 !important;
  opacity: 0.4;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text, button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown:disabled span.lav-components-text-color-black.lav-components-text, button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown.ant-btn-disabled span.lav-components-text-color-black.lav-components-text {
  color: #b4b1bc !important;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown-variant-plain:disabled {
  color: #b4b1bc;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown-variant-plain:disabled.lav-components-button-state-disabled, button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown-variant-plain:disabled:disabled, button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown-variant-plain:disabled.ant-btn-disabled {
  border: 1px solid transparent !important;
  background-color: transparent !important;
  opacity: 0.4;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown-variant-plain:disabled.lav-components-button-state-disabled span.lav-components-text-color-black.lav-components-text, button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown-variant-plain:disabled:disabled span.lav-components-text-color-black.lav-components-text, button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown-variant-plain:disabled.ant-btn-disabled span.lav-components-text-color-black.lav-components-text {
  color: #b4b1bc !important;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown.lav-components-dropdown-separator-true span:last-of-type {
  height: calc(100% + 4px);
  border-left: 1px solid #e1e0e4;
  margin-left: 10px;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown.lav-components-dropdown-arrow-false {
  border-radius: 50%;
  padding: 0;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown span {
  align-items: center;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown :not(.lav-components-dropdown-arrow-false) span.ant-avatar.ant-avatar-icon {
  margin-right: 8px;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown:focus-visible:not(:disabled) {
  outline: 4px solid #9261ec;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown:active:not(:disabled).lav-components-button-icon-undefined {
  background: #ffffff;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown:not(:disabled).lav-components-dropdown-variant-default:not(.lav-components-dropdown-container-menuOpen-true):not(:focus) {
  background: #ffffff;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown:not(:disabled).lav-components-dropdown-variant-default:not(.lav-components-dropdown-container-menuOpen-true):not(:focus):hover {
  background: #ececee;
  color: #140c22;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown:not(:disabled).lav-components-dropdown-variant-default:not(.lav-components-dropdown-container-menuOpen-true):not(:focus):hover svg {
  color: #140c22;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown.lav-components-dropdown-container-menuOpen-true, button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown:focus.lav-components-button-icon-undefined {
  border: 1px solid #6a2be9 !important;
  box-shadow: 0px 0px 0px 2px #dbc5f6 !important;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown.lav-components-dropdown-container-menuOpen-true:hover, button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown:focus.lav-components-button-icon-undefined:hover {
  background-color: #ffffff !important;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown-size-small {
  height: 26px;
  line-height: 26px;
  padding: 0 6px 0 8px;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown-size-medium {
  padding: 0 4px 0 8px;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown-size-medium div {
  height: 34px;
  line-height: 34px;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown-size-large {
  padding: 0 8px 0 12px;
}
button.ant-dropdown-trigger.ant-btn.lav-components-button.lav-components-dropdown-size-large div {
  height: 42px;
  line-height: 42px;
}

ul.ant-dropdown-menu li.ant-dropdown-menu-item[role=menuitem] {
  border-radius: 8px;
  color: #140c22;
  min-width: 172px;
}
ul.ant-dropdown-menu li.ant-dropdown-menu-item[role=menuitem]:hover, ul.ant-dropdown-menu li.ant-dropdown-menu-item[role=menuitem].ant-dropdown-menu-item-selected {
  background-color: #ece2f9;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

div.lav-components-menu-wrapper {
  z-index: 1;
  position: relative;
  width: fit-content;
  padding: 8px;
  border: 1px solid #e1e0e4;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 8px 20px -16px rgba(0, 0, 0, 0.08);
  background: #ffffff;
}
div.lav-components-menu-wrapper ul.ant-menu-root.ant-menu-vertical.lav-components-menu {
  display: flex;
  width: 100%;
  border-inline-end: none;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
}
div.lav-components-menu-wrapper ul.ant-menu-root.ant-menu-vertical.lav-components-menu:not(:first-child) {
  gap: 6px;
}
div.lav-components-menu-wrapper ul.ant-menu-root.ant-menu-vertical.lav-components-menu::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}
div.lav-components-menu-wrapper ul.ant-menu-root.ant-menu-vertical.lav-components-menu::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #e1e0e4;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

div.lav-components-paywall-banner {
  display: flex;
  padding: 16px 20px 12px 16px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #d7fb5b;
  background: #f5ffc8;
  height: calc(100% - 32px + 2px);
}
div.lav-components-paywall-banner .lav-components-paywall-banner-close-button {
  position: absolute;
  right: 14px;
  top: 14px;
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* https://fonts.google.com/specimen/Bricolage+Grotesque */
/* https://fonts.google.com/specimen/Inter */
span.lav-components-text {
  color: #000000;
}
span.lav-components-text-underline-true {
  text-decoration: underline;
}
span.lav-components-text-color-lavender {
  color: #6a2be9;
}
span.lav-components-text-color-black {
  color: #000000;
}
span.lav-components-text-color-white {
  color: #ffffff;
}
span.lav-components-text-color-pear {
  color: #caf344;
}
span.lav-components-text-color-sapphire {
  color: #2285e2;
}
span.lav-components-text-color-marigold {
  color: #fdd74c;
}
span.lav-components-text-color-peony {
  color: #ee448e;
}
span.lav-components-text-color-tart {
  color: #f05656;
}
span.lav-components-text-color-surf {
  color: #47e5b6;
}
span.lav-components-text-color-dark {
  color: #140c22;
}
span.lav-components-text-color-positive {
  color: #75c410;
}
span.lav-components-text-color-negative {
  color: #f05656;
}
span.lav-components-text-color-warning {
  color: #f6a40a;
}
span.lav-components-text-size-body1 {
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 18px;
  line-height: 18px; /* 150% */
  margin: -4.5px 0;
}
span.lav-components-text-size-body2 {
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 21px;
  line-height: 21px; /* 150% */
  margin: -5.5px 0;
}
span.lav-components-text-size-body3 {
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 24px;
  line-height: 24px; /* 150% */
  margin: -6px 0;
}
span.lav-components-text-size-subtext1 {
  font-size: 12px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 18px;
  margin: -5px 0;
  line-height: 18px; /* 150% */
  font-weight: 500;
  letter-spacing: normal;
}
span.lav-components-text-size-subtext2 {
  font-size: 14px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 21px;
  margin: -6px 0;
  line-height: 21px; /* 150% */
}
span.lav-components-text-size-subtext3 {
  font-size: 16px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 24px;
  margin: -6.5px 0;
  line-height: 24px; /* 150% */
}
span.lav-components-text-size-subtext4 {
  font-size: 20px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 30px;
  margin: -5.5px 0;
  line-height: 24px; /* 120% */
}
span.lav-components-text-size-subtext5 {
  font-size: 24px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 36px;
  margin: -6px 0;
  line-height: 28px; /* 116.667% */
}
span.lav-components-text-size-header1 {
  font-size: 36px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 54px;
  margin: -12.5px 0;
  line-height: 48px; /* 133.333% */
}
span.lav-components-text-size-header2 {
  font-size: 40px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 60px;
  margin: -15px 0;
  line-height: 56px; /* 140% */
}
span.lav-components-text-size-header3 {
  font-size: 48px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 72px;
  margin: -20px 0;
  line-height: 72px; /* 150% */
}

div.lav-components-search-container {
  width: 100%;
}

span.ant-input-affix-wrapper.lav-components-menu-search {
  display: flex;
  align-items: center;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #ececee;
  height: 40px;
  font-size: 14px;
  padding: 0 12px;
}
span.ant-input-affix-wrapper.lav-components-menu-search .ant-input-clear-icon {
  display: flex;
}
span.ant-input-affix-wrapper.lav-components-menu-search input.ant-input {
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 21px;
}
span.ant-input-affix-wrapper.lav-components-menu-search .ant-input-prefix {
  margin-inline-end: 8px;
}
span.ant-input-affix-wrapper.lav-components-menu-search .ant-input-wrapper,
span.ant-input-affix-wrapper.lav-components-menu-search .ant-input-wrapper * {
  height: 100%;
}
span.ant-input-affix-wrapper.lav-components-menu-search:focus-within {
  box-shadow: none;
  border-bottom: 1px solid #e1e0e4;
}
span.ant-input-affix-wrapper.lav-components-menu-search:hover,
span.ant-input-affix-wrapper.lav-components-menu-search:hover * {
  background: #f8f7f8;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* https://fonts.google.com/specimen/Bricolage+Grotesque */
/* https://fonts.google.com/specimen/Inter */
span.lav-components-text {
  color: #000000;
}
span.lav-components-text-underline-true {
  text-decoration: underline;
}
span.lav-components-text-color-lavender {
  color: #6a2be9;
}
span.lav-components-text-color-black {
  color: #000000;
}
span.lav-components-text-color-white {
  color: #ffffff;
}
span.lav-components-text-color-pear {
  color: #caf344;
}
span.lav-components-text-color-sapphire {
  color: #2285e2;
}
span.lav-components-text-color-marigold {
  color: #fdd74c;
}
span.lav-components-text-color-peony {
  color: #ee448e;
}
span.lav-components-text-color-tart {
  color: #f05656;
}
span.lav-components-text-color-surf {
  color: #47e5b6;
}
span.lav-components-text-color-dark {
  color: #140c22;
}
span.lav-components-text-color-positive {
  color: #75c410;
}
span.lav-components-text-color-negative {
  color: #f05656;
}
span.lav-components-text-color-warning {
  color: #f6a40a;
}
span.lav-components-text-size-body1 {
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 18px;
  line-height: 18px; /* 150% */
  margin: -4.5px 0;
}
span.lav-components-text-size-body2 {
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 21px;
  line-height: 21px; /* 150% */
  margin: -5.5px 0;
}
span.lav-components-text-size-body3 {
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 24px;
  line-height: 24px; /* 150% */
  margin: -6px 0;
}
span.lav-components-text-size-subtext1 {
  font-size: 12px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 18px;
  margin: -5px 0;
  line-height: 18px; /* 150% */
  font-weight: 500;
  letter-spacing: normal;
}
span.lav-components-text-size-subtext2 {
  font-size: 14px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 21px;
  margin: -6px 0;
  line-height: 21px; /* 150% */
}
span.lav-components-text-size-subtext3 {
  font-size: 16px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 24px;
  margin: -6.5px 0;
  line-height: 24px; /* 150% */
}
span.lav-components-text-size-subtext4 {
  font-size: 20px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 30px;
  margin: -5.5px 0;
  line-height: 24px; /* 120% */
}
span.lav-components-text-size-subtext5 {
  font-size: 24px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 36px;
  margin: -6px 0;
  line-height: 28px; /* 116.667% */
}
span.lav-components-text-size-header1 {
  font-size: 36px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 54px;
  margin: -12.5px 0;
  line-height: 48px; /* 133.333% */
}
span.lav-components-text-size-header2 {
  font-size: 40px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 60px;
  margin: -15px 0;
  line-height: 56px; /* 140% */
}
span.lav-components-text-size-header3 {
  font-size: 48px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 72px;
  margin: -20px 0;
  line-height: 72px; /* 150% */
}

div.lav-components-search-container {
  width: 100%;
}

span.ant-input-group-wrapper.lav-components-search {
  display: flex;
  align-items: center;
  border-radius: 8px !important;
  margin: 1px;
}
span.ant-input-group-wrapper.lav-components-search .ant-input-clear-icon {
  display: flex;
}
span.ant-input-group-wrapper.lav-components-search:hover {
  border-color: #d6d4da;
  border-width: 2px;
  margin: 0;
}
span.ant-input-group-wrapper.lav-components-search .ant-input-wrapper,
span.ant-input-group-wrapper.lav-components-search .ant-input-wrapper * {
  height: 100%;
}
span.ant-input-group-wrapper.lav-components-search button.ant-input-search-button {
  width: 36px;
  border: none;
  box-shadow: unset;
}
span.ant-input-group-wrapper.lav-components-search button.ant-input-search-button span.ant-btn-icon span.anticon.anticon-search svg {
  color: #140c22;
}
span.ant-input-group-wrapper.lav-components-search button.ant-input-search-button:focus, span.ant-input-group-wrapper.lav-components-search button.ant-input-search-button:hover {
  color: #d6d4da !important;
  box-shadow: none;
}
span.ant-input-group-wrapper.lav-components-search-state-disabled .ant-input-group-addon {
  background-color: unset;
  position: unset;
}
span.ant-input-group-wrapper.lav-components-search-state-disabled .ant-input-group-addon svg {
  opacity: 0.4;
}
span.ant-input-group-wrapper.lav-components-search .ant-input-affix-wrapper {
  align-items: center;
  border: none;
  box-shadow: unset;
}
span.ant-input-group-wrapper.lav-components-search .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
  border-right: 1px solid #d6d4da;
}
span.ant-input-group-wrapper.lav-components-search .ant-input-affix-wrapper input.ant-input {
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 21px;
  color: #140c22;
}
span.ant-input-group-wrapper.lav-components-search .ant-input-affix-wrapper input:focus,
span.ant-input-group-wrapper.lav-components-search .ant-input-affix-wrapper span:hover {
  box-shadow: none;
}
span.ant-input-group-wrapper.lav-components-search span.ant-input-group-addon:last-child {
  border-left: 1px solid #d6d4da;
}
span.ant-input-group-wrapper.lav-components-search .ant-input-wrapper {
  border-radius: 8px;
  border: 1px solid #e1e0e4;
  /* Focus state for wrapper */
  /* Focus state for wrapper */
}
span.ant-input-group-wrapper.lav-components-search .ant-input-wrapper:hover {
  border: 2px solid #d6d4da;
}
span.ant-input-group-wrapper.lav-components-search .ant-input-wrapper:focus-within {
  border: 1px solid #9261ec;
  box-shadow: 0px 0px 0px 2px #d5cbf2;
}
span.ant-input-group-wrapper.lav-components-search-size-small {
  height: 32px;
  font-size: 12px;
  padding: 0 10px;
}
span.ant-input-group-wrapper.lav-components-search-size-medium {
  height: 40px;
  font-size: 14px;
  padding: 0 12px;
}
span.ant-input-group-wrapper.lav-components-search-state-disabled {
  color: #b4b1bc;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}
.lav-shimmer-animate {
  animation: shimmer 2s infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* https://fonts.google.com/specimen/Bricolage+Grotesque */
/* https://fonts.google.com/specimen/Inter */
span.lav-components-text {
  color: #000000;
}
span.lav-components-text-underline-true {
  text-decoration: underline;
}
span.lav-components-text-color-lavender {
  color: #6a2be9;
}
span.lav-components-text-color-black {
  color: #000000;
}
span.lav-components-text-color-white {
  color: #ffffff;
}
span.lav-components-text-color-pear {
  color: #caf344;
}
span.lav-components-text-color-sapphire {
  color: #2285e2;
}
span.lav-components-text-color-marigold {
  color: #fdd74c;
}
span.lav-components-text-color-peony {
  color: #ee448e;
}
span.lav-components-text-color-tart {
  color: #f05656;
}
span.lav-components-text-color-surf {
  color: #47e5b6;
}
span.lav-components-text-color-dark {
  color: #140c22;
}
span.lav-components-text-color-positive {
  color: #75c410;
}
span.lav-components-text-color-negative {
  color: #f05656;
}
span.lav-components-text-color-warning {
  color: #f6a40a;
}
span.lav-components-text-size-body1 {
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 18px;
  line-height: 18px; /* 150% */
  margin: -4.5px 0;
}
span.lav-components-text-size-body2 {
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 21px;
  line-height: 21px; /* 150% */
  margin: -5.5px 0;
}
span.lav-components-text-size-body3 {
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 24px;
  line-height: 24px; /* 150% */
  margin: -6px 0;
}
span.lav-components-text-size-subtext1 {
  font-size: 12px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 18px;
  margin: -5px 0;
  line-height: 18px; /* 150% */
  font-weight: 500;
  letter-spacing: normal;
}
span.lav-components-text-size-subtext2 {
  font-size: 14px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 21px;
  margin: -6px 0;
  line-height: 21px; /* 150% */
}
span.lav-components-text-size-subtext3 {
  font-size: 16px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 24px;
  margin: -6.5px 0;
  line-height: 24px; /* 150% */
}
span.lav-components-text-size-subtext4 {
  font-size: 20px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 30px;
  margin: -5.5px 0;
  line-height: 24px; /* 120% */
}
span.lav-components-text-size-subtext5 {
  font-size: 24px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 36px;
  margin: -6px 0;
  line-height: 28px; /* 116.667% */
}
span.lav-components-text-size-header1 {
  font-size: 36px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 54px;
  margin: -12.5px 0;
  line-height: 48px; /* 133.333% */
}
span.lav-components-text-size-header2 {
  font-size: 40px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 60px;
  margin: -15px 0;
  line-height: 56px; /* 140% */
}
span.lav-components-text-size-header3 {
  font-size: 48px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 72px;
  margin: -20px 0;
  line-height: 72px; /* 150% */
}

div.ant-tabs.lav-components-tabs-variant-underlined.lav-components-tabs-size-small div.ant-tabs-tab-btn {
  font-size: 14px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.1px;
  line-height: 21px;
}
div.ant-tabs.lav-components-tabs-variant-underlined.lav-components-tabs-size-small div.ant-tabs-tab {
  padding: 5px 0;
}
div.ant-tabs.lav-components-tabs-variant-underlined.lav-components-tabs-size-medium div.ant-tabs-tab-btn {
  font-size: 16px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.1px;
  line-height: 24px;
}
div.ant-tabs.lav-components-tabs-variant-underlined.lav-components-tabs-size-medium div.ant-tabs-tab {
  padding: 7px 0;
}
div.ant-tabs.lav-components-tabs-variant-underlined div.ant-tabs-tab-btn[role=tab] {
  color: #000000;
}
div.ant-tabs.lav-components-tabs-variant-underlined div.ant-tabs-tab-btn[role=tab][aria-selected=false] {
  color: #858092;
}
div.ant-tabs.lav-components-tabs-variant-underlined div.ant-tabs-tab-btn[role=tab]:hover {
  color: #6a2be9;
}
div.ant-tabs.lav-components-tabs-variant-underlined .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background-color: #6a2be9;
}
div.ant-tabs.lav-components-tabs-variant-filled.lav-components-tabs-size-small div.ant-tabs-nav-list div.ant-tabs-tab {
  font-size: 14px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.1px;
  line-height: 21px;
  padding: 2px 10px;
}
div.ant-tabs.lav-components-tabs-variant-filled.lav-components-tabs-size-medium div.ant-tabs-nav-list div.ant-tabs-tab {
  font-size: 16px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.1px;
  line-height: 24px;
  padding: 3px 12px;
}
div.ant-tabs.lav-components-tabs-variant-filled div.ant-tabs-nav[role=tablist]::before {
  display: none;
}
div.ant-tabs.lav-components-tabs-variant-filled div.ant-tabs-nav[role=tablist] div.ant-tabs-nav-list div.ant-tabs-tab {
  margin-right: 2px;
  border-radius: 8px;
  border: none;
  background: none;
}
div.ant-tabs.lav-components-tabs-variant-filled div.ant-tabs-nav[role=tablist] div.ant-tabs-nav-list div.ant-tabs-tab:hover {
  background-color: #ececee;
}
div.ant-tabs.lav-components-tabs-variant-filled div.ant-tabs-nav[role=tablist] div.ant-tabs-nav-list div.ant-tabs-tab:hover div.ant-tabs-tab-btn[role=tab] {
  color: #000000;
}
div.ant-tabs.lav-components-tabs-variant-filled div.ant-tabs-nav[role=tablist] div.ant-tabs-nav-list div.ant-tabs-tab.ant-tabs-tab-active {
  background-color: #230a54;
}
div.ant-tabs.lav-components-tabs-variant-filled div.ant-tabs-nav[role=tablist] div.ant-tabs-nav-list div.ant-tabs-tab.ant-tabs-tab-active:hover {
  background-color: #585462;
}
div.ant-tabs.lav-components-tabs-variant-filled div.ant-tabs-nav[role=tablist] div.ant-tabs-nav-list div.ant-tabs-tab div.ant-tabs-tab-btn[role=tab] {
  color: #858092;
}
div.ant-tabs.lav-components-tabs-variant-filled div.ant-tabs-nav[role=tablist] div.ant-tabs-nav-list div.ant-tabs-tab div.ant-tabs-tab-btn[role=tab][aria-selected=true] {
  color: #ffffff;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* https://fonts.google.com/specimen/Bricolage+Grotesque */
/* https://fonts.google.com/specimen/Inter */
span.lav-components-text {
  color: #000000;
}
span.lav-components-text-underline-true {
  text-decoration: underline;
}
span.lav-components-text-color-lavender {
  color: #6a2be9;
}
span.lav-components-text-color-black {
  color: #000000;
}
span.lav-components-text-color-white {
  color: #ffffff;
}
span.lav-components-text-color-pear {
  color: #caf344;
}
span.lav-components-text-color-sapphire {
  color: #2285e2;
}
span.lav-components-text-color-marigold {
  color: #fdd74c;
}
span.lav-components-text-color-peony {
  color: #ee448e;
}
span.lav-components-text-color-tart {
  color: #f05656;
}
span.lav-components-text-color-surf {
  color: #47e5b6;
}
span.lav-components-text-color-dark {
  color: #140c22;
}
span.lav-components-text-color-positive {
  color: #75c410;
}
span.lav-components-text-color-negative {
  color: #f05656;
}
span.lav-components-text-color-warning {
  color: #f6a40a;
}
span.lav-components-text-size-body1 {
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 18px;
  line-height: 18px; /* 150% */
  margin: -4.5px 0;
}
span.lav-components-text-size-body2 {
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 21px;
  line-height: 21px; /* 150% */
  margin: -5.5px 0;
}
span.lav-components-text-size-body3 {
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 24px;
  line-height: 24px; /* 150% */
  margin: -6px 0;
}
span.lav-components-text-size-subtext1 {
  font-size: 12px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 18px;
  margin: -5px 0;
  line-height: 18px; /* 150% */
  font-weight: 500;
  letter-spacing: normal;
}
span.lav-components-text-size-subtext2 {
  font-size: 14px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 21px;
  margin: -6px 0;
  line-height: 21px; /* 150% */
}
span.lav-components-text-size-subtext3 {
  font-size: 16px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 24px;
  margin: -6.5px 0;
  line-height: 24px; /* 150% */
}
span.lav-components-text-size-subtext4 {
  font-size: 20px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 30px;
  margin: -5.5px 0;
  line-height: 24px; /* 120% */
}
span.lav-components-text-size-subtext5 {
  font-size: 24px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 36px;
  margin: -6px 0;
  line-height: 28px; /* 116.667% */
}
span.lav-components-text-size-header1 {
  font-size: 36px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 54px;
  margin: -12.5px 0;
  line-height: 48px; /* 133.333% */
}
span.lav-components-text-size-header2 {
  font-size: 40px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 60px;
  margin: -15px 0;
  line-height: 56px; /* 140% */
}
span.lav-components-text-size-header3 {
  font-size: 48px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 72px;
  margin: -20px 0;
  line-height: 72px; /* 150% */
}

div.ant-tooltip div.ant-tooltip-content,
div.ant-tooltip-inner {
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 21px;
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* https://fonts.google.com/specimen/Bricolage+Grotesque */
/* https://fonts.google.com/specimen/Inter */
span.lav-components-text {
  color: #000000;
}
span.lav-components-text-underline-true {
  text-decoration: underline;
}
span.lav-components-text-color-lavender {
  color: #6a2be9;
}
span.lav-components-text-color-black {
  color: #000000;
}
span.lav-components-text-color-white {
  color: #ffffff;
}
span.lav-components-text-color-pear {
  color: #caf344;
}
span.lav-components-text-color-sapphire {
  color: #2285e2;
}
span.lav-components-text-color-marigold {
  color: #fdd74c;
}
span.lav-components-text-color-peony {
  color: #ee448e;
}
span.lav-components-text-color-tart {
  color: #f05656;
}
span.lav-components-text-color-surf {
  color: #47e5b6;
}
span.lav-components-text-color-dark {
  color: #140c22;
}
span.lav-components-text-color-positive {
  color: #75c410;
}
span.lav-components-text-color-negative {
  color: #f05656;
}
span.lav-components-text-color-warning {
  color: #f6a40a;
}
span.lav-components-text-size-body1 {
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 18px;
  line-height: 18px; /* 150% */
  margin: -4.5px 0;
}
span.lav-components-text-size-body2 {
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 21px;
  line-height: 21px; /* 150% */
  margin: -5.5px 0;
}
span.lav-components-text-size-body3 {
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 24px;
  line-height: 24px; /* 150% */
  margin: -6px 0;
}
span.lav-components-text-size-subtext1 {
  font-size: 12px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 18px;
  margin: -5px 0;
  line-height: 18px; /* 150% */
  font-weight: 500;
  letter-spacing: normal;
}
span.lav-components-text-size-subtext2 {
  font-size: 14px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 21px;
  margin: -6px 0;
  line-height: 21px; /* 150% */
}
span.lav-components-text-size-subtext3 {
  font-size: 16px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 24px;
  margin: -6.5px 0;
  line-height: 24px; /* 150% */
}
span.lav-components-text-size-subtext4 {
  font-size: 20px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 30px;
  margin: -5.5px 0;
  line-height: 24px; /* 120% */
}
span.lav-components-text-size-subtext5 {
  font-size: 24px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 36px;
  margin: -6px 0;
  line-height: 28px; /* 116.667% */
}
span.lav-components-text-size-header1 {
  font-size: 36px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 54px;
  margin: -12.5px 0;
  line-height: 48px; /* 133.333% */
}
span.lav-components-text-size-header2 {
  font-size: 40px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 60px;
  margin: -15px 0;
  line-height: 56px; /* 140% */
}
span.lav-components-text-size-header3 {
  font-size: 48px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 72px;
  margin: -20px 0;
  line-height: 72px; /* 150% */
}

div.ant-segmented.lav-components-segmented-control.lav-components-segmented-control-radius-xl {
  border-radius: 32px;
}
div.ant-segmented.lav-components-segmented-control.lav-components-segmented-control-radius-xl div.ant-segmented-group label.ant-segmented-item {
  border-radius: 32px;
}
div.ant-segmented.lav-components-segmented-control.lav-components-segmented-control-size-small div.ant-segmented-group label.ant-segmented-item div.ant-segmented-item-label {
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 18px;
  display: flex;
  height: 20px;
  line-height: 20px;
}
div.ant-segmented.lav-components-segmented-control.lav-components-segmented-control-size-small div.ant-segmented-group label.ant-segmented-item div.ant-segmented-item-label span.ant-segmented-item-icon svg {
  height: 100%;
}
div.ant-segmented.lav-components-segmented-control.lav-components-segmented-control-size-medium div.ant-segmented-group label.ant-segmented-item div.ant-segmented-item-label {
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 21px;
  display: flex;
  height: 28px;
  line-height: 28px;
}
div.ant-segmented.lav-components-segmented-control.lav-components-segmented-control-size-medium div.ant-segmented-group label.ant-segmented-item div.ant-segmented-item-label span.ant-segmented-item-icon svg {
  height: 100%;
}
div.ant-segmented.lav-components-segmented-control.lav-components-segmented-control-size-large div.ant-segmented-group label.ant-segmented-item div.ant-segmented-item-label {
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 21px;
  display: flex;
  height: 36px;
  line-height: 36px;
}
div.ant-segmented.lav-components-segmented-control.lav-components-segmented-control-size-large div.ant-segmented-group label.ant-segmented-item div.ant-segmented-item-label span.ant-segmented-item-icon svg {
  height: 100%;
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

button.ant-switch.lav-components-switch.ant-switch-checked {
  background-color: #6a2be9;
}
button.ant-switch.lav-components-switch.ant-switch-checked div.ant-switch-handle {
  top: 3px;
  width: 16px;
  height: 16px;
  inset-inline-start: calc(100% - 20px);
}
button.ant-switch.lav-components-switch.ant-switch-checked.ant-switch-small div.ant-switch-handle {
  top: 3px;
  width: 10px;
  height: 10px;
  inset-inline-start: calc(100% - 13px);
}
button.ant-switch.lav-components-switch.ant-switch-checked.ant-switch-disabled {
  opacity: 0.2;
}
button.ant-switch.lav-components-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background-color: #5318cc;
}
button.ant-switch.lav-components-switch:not(.ant-switch-checked) {
  background-color: #cbc8d0;
}
button.ant-switch.lav-components-switch:not(.ant-switch-checked) div.ant-switch-handle {
  top: 3px;
  width: 16px;
  height: 16px;
  inset-inline-start: 4px;
}
button.ant-switch.lav-components-switch:not(.ant-switch-checked).ant-switch-small div.ant-switch-handle {
  top: 3px;
  width: 10px;
  height: 10px;
  inset-inline-start: 3px;
}
button.ant-switch.lav-components-switch:not(.ant-switch-checked).ant-switch-disabled {
  opacity: 0.48;
}
button.ant-switch.lav-components-switch:not(.ant-switch-checked):hover:not(.ant-switch-disabled) {
  background-color: #858092;
}
button.ant-switch.lav-components-switch:focus-visible {
  outline: none;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* https://fonts.google.com/specimen/Bricolage+Grotesque */
/* https://fonts.google.com/specimen/Inter */
span.lav-components-text {
  color: #000000;
}
span.lav-components-text-underline-true {
  text-decoration: underline;
}
span.lav-components-text-color-lavender {
  color: #6a2be9;
}
span.lav-components-text-color-black {
  color: #000000;
}
span.lav-components-text-color-white {
  color: #ffffff;
}
span.lav-components-text-color-pear {
  color: #caf344;
}
span.lav-components-text-color-sapphire {
  color: #2285e2;
}
span.lav-components-text-color-marigold {
  color: #fdd74c;
}
span.lav-components-text-color-peony {
  color: #ee448e;
}
span.lav-components-text-color-tart {
  color: #f05656;
}
span.lav-components-text-color-surf {
  color: #47e5b6;
}
span.lav-components-text-color-dark {
  color: #140c22;
}
span.lav-components-text-color-positive {
  color: #75c410;
}
span.lav-components-text-color-negative {
  color: #f05656;
}
span.lav-components-text-color-warning {
  color: #f6a40a;
}
span.lav-components-text-size-body1 {
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 18px;
  line-height: 18px; /* 150% */
  margin: -4.5px 0;
}
span.lav-components-text-size-body2 {
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 21px;
  line-height: 21px; /* 150% */
  margin: -5.5px 0;
}
span.lav-components-text-size-body3 {
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 24px;
  line-height: 24px; /* 150% */
  margin: -6px 0;
}
span.lav-components-text-size-subtext1 {
  font-size: 12px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 18px;
  margin: -5px 0;
  line-height: 18px; /* 150% */
  font-weight: 500;
  letter-spacing: normal;
}
span.lav-components-text-size-subtext2 {
  font-size: 14px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 21px;
  margin: -6px 0;
  line-height: 21px; /* 150% */
}
span.lav-components-text-size-subtext3 {
  font-size: 16px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 24px;
  margin: -6.5px 0;
  line-height: 24px; /* 150% */
}
span.lav-components-text-size-subtext4 {
  font-size: 20px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 30px;
  margin: -5.5px 0;
  line-height: 24px; /* 120% */
}
span.lav-components-text-size-subtext5 {
  font-size: 24px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 36px;
  margin: -6px 0;
  line-height: 28px; /* 116.667% */
}
span.lav-components-text-size-header1 {
  font-size: 36px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 54px;
  margin: -12.5px 0;
  line-height: 48px; /* 133.333% */
}
span.lav-components-text-size-header2 {
  font-size: 40px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 60px;
  margin: -15px 0;
  line-height: 56px; /* 140% */
}
span.lav-components-text-size-header3 {
  font-size: 48px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 72px;
  margin: -20px 0;
  line-height: 72px; /* 150% */
}

span.ant-input-affix-wrapper.lav-components-textarea {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 8px !important;
  width: 100%;
}
span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain textarea.ant-input {
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 21px;
}
span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain textarea.ant-input::-webkit-resizer {
  display: none;
}
span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain.lav-components-textarea-state-error {
  border: none;
}
span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain.lav-components-textarea-state-error textarea.ant-input.ant-input-status-error {
  background: #fadad6;
  border: none;
}
span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain.lav-components-textarea-state-warning {
  border: none;
}
span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain.lav-components-textarea-state-warning textarea.ant-input.ant-input-status-warning {
  background: #fffae5;
  border: none;
}
span.ant-input-affix-wrapper.lav-components-textarea:not(span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain) {
  border: 1px solid #e1e0e4;
}
span.ant-input-affix-wrapper.lav-components-textarea:not(span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain) textarea.ant-input::-webkit-resizer {
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M6 6L12 0V9C12 10.6569 10.6569 12 9 12H0L6 6Z" fill="%23e1e0e4"/></svg>');
}
span.ant-input-affix-wrapper.lav-components-textarea:not(span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain) textarea.ant-input.ant-input-status-error::-webkit-resizer {
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M6 6L12 0V9C12 10.6569 10.6569 12 9 12H0L6 6Z" fill="%23fadad6"/></svg>');
}
span.ant-input-affix-wrapper.lav-components-textarea:not(span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain) textarea.ant-input.ant-input-status-warning::-webkit-resizer {
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M6 6L12 0V9C12 10.6569 10.6569 12 9 12H0L6 6Z" fill="%23fde98a"/></svg>');
}
span.ant-input-affix-wrapper.lav-components-textarea:not(span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain):hover {
  border-width: 2px;
  border-color: #cbc8d0;
}
span.ant-input-affix-wrapper.lav-components-textarea:not(span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain):hover textarea.ant-input:not(.ant-input-status-warning):not(.ant-input-status-error)::-webkit-resizer {
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M6 6L12 0V9C12 10.6569 10.6569 12 9 12H0L6 6Z" fill="%23cbc8d0"/></svg>');
}
span.ant-input-affix-wrapper.lav-components-textarea:not(span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain):focus-within,
span.ant-input-affix-wrapper.lav-components-textarea:not(span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain) textarea.ant-input:focus:not(.ant-input-status-warning):not(.ant-input-status-error) {
  border: 1px solid #9261ec;
  box-shadow: 0px 0px 0px 2px #dbc5f6;
}
span.ant-input-affix-wrapper.lav-components-textarea:not(span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain):focus-within::-webkit-resizer,
span.ant-input-affix-wrapper.lav-components-textarea:not(span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain) textarea.ant-input:focus:not(.ant-input-status-warning):not(.ant-input-status-error)::-webkit-resizer {
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M6 6L12 0V9C12 10.6569 10.6569 12 9 12H0L6 6Z" fill="%23dbc5f6"/></svg>');
}
span.ant-input-affix-wrapper.lav-components-textarea:not(span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain).lav-components-textarea-state-error {
  border: 2px solid #f05656;
}
span.ant-input-affix-wrapper.lav-components-textarea:not(span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain).lav-components-textarea-state-error textarea.ant-input.ant-input-status-error {
  border: none;
}
span.ant-input-affix-wrapper.lav-components-textarea:not(span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain).lav-components-textarea-state-warning {
  border: 2px solid #fdd74c;
}
span.ant-input-affix-wrapper.lav-components-textarea:not(span.ant-input-affix-wrapper.lav-components-textarea.lav-components-textarea-variant-plain).lav-components-textarea-state-warning textarea.ant-input.ant-input-status-warning {
  border: none;
}
span.ant-input-affix-wrapper.lav-components-textarea-state-disabled textarea.ant-input {
  color: #b4b1bc;
}
span.ant-input-affix-wrapper.lav-components-textarea-state-disabled textarea.ant-input::-webkit-resizer {
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M6 6L12 0V9C12 10.6569 10.6569 12 9 12H0L6 6Z" fill="%23ececee"/></svg>');
}

div.lav-components-textarea-error-text,
div.lav-components-textarea-warning-text {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
div.lav-components-textarea-error-text.lav-components-textarea-error-text,
div.lav-components-textarea-warning-text.lav-components-textarea-error-text {
  color: #cb261a;
}
div.lav-components-textarea-error-text.lav-components-textarea-warning-text,
div.lav-components-textarea-warning-text.lav-components-textarea-warning-text {
  color: #f6a40a;
}
div.lav-components-textarea-error-text span:nth-child(2),
div.lav-components-textarea-warning-text span:nth-child(2) {
  margin-left: 8px;
  overflow: hidden;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

.lav-components-icon-color-lavender {
  color: #6a2be9;
}
.lav-components-icon-color-black {
  color: #000000;
}
.lav-components-icon-color-white {
  color: #ffffff;
}
.lav-components-icon-color-pear {
  color: #caf344;
}
.lav-components-icon-color-sapphire {
  color: #2285e2;
}
.lav-components-icon-color-marigold {
  color: #fdd74c;
}
.lav-components-icon-color-peony {
  color: #ee448e;
}
.lav-components-icon-color-tart {
  color: #f05656;
}
.lav-components-icon-color-surf {
  color: #47e5b6;
}
.lav-components-icon-color-dark {
  color: #140c22;
}
.lav-components-icon-color-positive {
  color: #75c410;
}
.lav-components-icon-color-negative {
  color: #f05656;
}
.lav-components-icon-color-warning {
  color: #f6a40a;
}
.lav-components-icon-size-mini svg {
  height: 14px;
  width: 14px;
  stroke-width: 1.4px;
}
.lav-components-icon-size-extra-small svg {
  height: 16px;
  width: 16px;
  stroke-width: 1.4px;
}
.lav-components-icon-size-small svg {
  height: 20px;
  width: 20px;
  stroke-width: 1.6px;
}
.lav-components-icon-size-medium svg {
  height: 24px;
  width: 24px;
  stroke-width: 1.8px;
}
.lav-components-icon-size-large svg {
  height: 32px;
  width: 32px;
  stroke-width: 2.4px;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* https://fonts.google.com/specimen/Bricolage+Grotesque */
/* https://fonts.google.com/specimen/Inter */
span.lav-components-text {
  color: #000000;
}
span.lav-components-text-underline-true {
  text-decoration: underline;
}
span.lav-components-text-color-lavender {
  color: #6a2be9;
}
span.lav-components-text-color-black {
  color: #000000;
}
span.lav-components-text-color-white {
  color: #ffffff;
}
span.lav-components-text-color-pear {
  color: #caf344;
}
span.lav-components-text-color-sapphire {
  color: #2285e2;
}
span.lav-components-text-color-marigold {
  color: #fdd74c;
}
span.lav-components-text-color-peony {
  color: #ee448e;
}
span.lav-components-text-color-tart {
  color: #f05656;
}
span.lav-components-text-color-surf {
  color: #47e5b6;
}
span.lav-components-text-color-dark {
  color: #140c22;
}
span.lav-components-text-color-positive {
  color: #75c410;
}
span.lav-components-text-color-negative {
  color: #f05656;
}
span.lav-components-text-color-warning {
  color: #f6a40a;
}
span.lav-components-text-size-body1 {
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 18px;
  line-height: 18px; /* 150% */
  margin: -4.5px 0;
}
span.lav-components-text-size-body2 {
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 21px;
  line-height: 21px; /* 150% */
  margin: -5.5px 0;
}
span.lav-components-text-size-body3 {
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 24px;
  line-height: 24px; /* 150% */
  margin: -6px 0;
}
span.lav-components-text-size-subtext1 {
  font-size: 12px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 18px;
  margin: -5px 0;
  line-height: 18px; /* 150% */
  font-weight: 500;
  letter-spacing: normal;
}
span.lav-components-text-size-subtext2 {
  font-size: 14px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 21px;
  margin: -6px 0;
  line-height: 21px; /* 150% */
}
span.lav-components-text-size-subtext3 {
  font-size: 16px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 24px;
  margin: -6.5px 0;
  line-height: 24px; /* 150% */
}
span.lav-components-text-size-subtext4 {
  font-size: 20px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 30px;
  margin: -5.5px 0;
  line-height: 24px; /* 120% */
}
span.lav-components-text-size-subtext5 {
  font-size: 24px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 36px;
  margin: -6px 0;
  line-height: 28px; /* 116.667% */
}
span.lav-components-text-size-header1 {
  font-size: 36px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 54px;
  margin: -12.5px 0;
  line-height: 48px; /* 133.333% */
}
span.lav-components-text-size-header2 {
  font-size: 40px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 60px;
  margin: -15px 0;
  line-height: 56px; /* 140% */
}
span.lav-components-text-size-header3 {
  font-size: 48px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 72px;
  margin: -20px 0;
  line-height: 72px; /* 150% */
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* sizes */
/* type scales */
/* radii */
/* shadows */
:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-60: 60px;
  --size-64: 64px;
  --size-72: 72px;
  --size-80: 80px;
  --size-88: 88px;
  --type-scale-10: 10;
  --type-scale-12: 12;
  --type-scale-14: 14;
  --type-scale-16: 16;
  --type-scale-20: 20;
  --type-scale-24: 24;
  --type-scale-36: 36;
  --type-scale-48: 48;
  --type-scale-60: 60;
  --radius-none: 0;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-md-lrg: 12px;
  --radius-lg: 16px;
  --radius-xl: 32px;
  --radius-2xl: 64px;
  --shadow-sm: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  --shadow-md: 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Neutral colors */
/*** Blue ***/
/*** Purple ***/
/*** Theme helpers ***/
/**** uncomment for blue palette *
$color-neutral-50: $color-neutral-blue-50;
$color-neutral-100: $color-neutral-blue-100;
$color-neutral-200: $color-neutral-blue-200;
$color-neutral-300: $color-neutral-blue-300;
$color-neutral-400: $color-neutral-blue-400;
$color-neutral-500: $color-neutral-blue-500;
$color-neutral-600: $color-neutral-blue-600;
$color-neutral-700: $color-neutral-blue-700;
$color-neutral-800: $color-neutral-blue-800;
$color-neutral-900: $color-neutral-blue-900;
/**** uncomment for purple palette */
/* Extended palette */
/*** Lavender palette ***/
/*** Pear palette ***/
/*** Sapphire palette ***/
/*** Marigold palette ***/
/*** Peony palette ***/
/*** Tart palette ***/
/*** Surf palette ***/
/* Core colors */
/*** Primary colors ***/
/*** Secondary colors ***/
/*** Utility colors ***/
/*** Base colors ***/
:root {
  --color-primary-lavender: #6a2be9;
  --color-primary-dark-lavender: #230a54;
  --color-primary-white-shore: #f7f4f0;
  --color-primary-eggshell: #fffdf9;
  --color-secondary-pear: #caf344;
  --color-secondary-sapphire: #2285e2;
  --color-secondary-marigold: #fdd74c;
  --color-secondary-peony: #ee448e;
  --color-utility-Positive: #75c410;
  --color-utility-Negative: #f05656;
  --color-utility-Warning: #f6a40a;
  --color-base-White: #ffffff;
  --color-base-Black: #000000;
  --color-neutral-blue-50: #f6f8fc;
  --color-neutral-blue-100: #e6e9f0;
  --color-neutral-blue-200: #d2d6df;
  --color-neutral-blue-300: #bcc0c9;
  --color-neutral-blue-400: #a1a6af;
  --color-neutral-blue-500: #868c96;
  --color-neutral-blue-600: #6d727d;
  --color-neutral-blue-700: #555a63;
  --color-neutral-blue-800: #323a4a;
  --color-neutral-blue-900: #18253d;
  --color-neutral-purple-50: #f8f7f8;
  --color-neutral-purple-100: #ececee;
  --color-neutral-purple-200: #e1e0e4;
  --color-neutral-purple-300: #d6d4da;
  --color-neutral-purple-400: #cbc8d0;
  --color-neutral-purple-500: #b4b1bc;
  --color-neutral-purple-600: #858092;
  --color-neutral-purple-700: #585462;
  --color-neutral-purple-800: #423f4a;
  --color-neutral-purple-900: #140c22;
  --color-neutral-50: #f8f7f8;
  --color-neutral-100: #ececee;
  --color-neutral-200: #e1e0e4;
  --color-neutral-300: #d6d4da;
  --color-neutral-400: #cbc8d0;
  --color-neutral-500: #b4b1bc;
  --color-neutral-600: #858092;
  --color-neutral-700: #585462;
  --color-neutral-800: #423f4a;
  --color-neutral-900: #140c22;
  --color-lavender-50: #ece2f9;
  --color-lavender-100: #dbc5f6;
  --color-lavender-200: #cdaff3;
  --color-lavender-300: #bb96ef;
  --color-lavender-400: #9261ec;
  --color-lavender-500: #6a2be9;
  --color-lavender-600: #5318cc;
  --color-lavender-700: #42139f;
  --color-lavender-800: #230a54;
  --color-pear-50: #f5ffc8;
  --color-pear-100: #eaff97;
  --color-pear-200: #d7fb5b;
  --color-pear-300: #caf344;
  --color-pear-400: #afe222;
  --color-pear-500: #89b918;
  --color-pear-600: #638c12;
  --color-pear-700: #415f0c;
  --color-pear-800: #203207;
  --color-sapphire-50: #d6edfa;
  --color-sapphire-100: #a9d7f4;
  --color-sapphire-200: #7cbfee;
  --color-sapphire-300: #439de7;
  --color-sapphire-400: #2285e2;
  --color-sapphire-500: #1a70cb;
  --color-sapphire-600: #1861b9;
  --color-sapphire-700: #12458c;
  --color-sapphire-800: #0c2c5f;
  --color-marigold-50: #fffae5;
  --color-marigold-100: #fef2be;
  --color-marigold-200: #fde98a;
  --color-marigold-300: #fdd74c;
  --color-marigold-400: #fcc72c;
  --color-marigold-500: #f6a40a;
  --color-marigold-600: #da7c05;
  --color-marigold-700: #b55708;
  --color-marigold-800: #78380f;
  --color-peony-50: #fde8f4;
  --color-peony-100: #fac2e1;
  --color-peony-200: #f79cc9;
  --color-peony-300: #f377b1;
  --color-peony-400: #ee448e;
  --color-peony-500: #d31360;
  --color-peony-600: #ad1049;
  --color-peony-700: #880c35;
  --color-peony-800: #630823;
  --color-tart-50: #fadad6;
  --color-tart-100: #f4aea9;
  --color-tart-200: #ee807c;
  --color-tart-300: #f05656;
  --color-tart-400: #e22922;
  --color-tart-500: #cb261a;
  --color-tart-600: #b92818;
  --color-tart-700: #8c2312;
  --color-tart-800: #5f1a0c;
  --color-surf-50: #cefde8;
  --color-surf-100: #a1f9d7;
  --color-surf-200: #64f1c2;
  --color-surf-300: #47e5b6;
  --color-surf-400: #02c793;
  --color-surf-500: #00a279;
  --color-surf-600: #008264;
  --color-surf-700: #006750;
  --color-surf-800: #005444;
}

/* https://fonts.google.com/specimen/Bricolage+Grotesque */
/* https://fonts.google.com/specimen/Inter */
span.lav-components-text {
  color: #000000;
}
span.lav-components-text-underline-true {
  text-decoration: underline;
}
span.lav-components-text-color-lavender {
  color: #6a2be9;
}
span.lav-components-text-color-black {
  color: #000000;
}
span.lav-components-text-color-white {
  color: #ffffff;
}
span.lav-components-text-color-pear {
  color: #caf344;
}
span.lav-components-text-color-sapphire {
  color: #2285e2;
}
span.lav-components-text-color-marigold {
  color: #fdd74c;
}
span.lav-components-text-color-peony {
  color: #ee448e;
}
span.lav-components-text-color-tart {
  color: #f05656;
}
span.lav-components-text-color-surf {
  color: #47e5b6;
}
span.lav-components-text-color-dark {
  color: #140c22;
}
span.lav-components-text-color-positive {
  color: #75c410;
}
span.lav-components-text-color-negative {
  color: #f05656;
}
span.lav-components-text-color-warning {
  color: #f6a40a;
}
span.lav-components-text-size-body1 {
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 18px;
  line-height: 18px; /* 150% */
  margin: -4.5px 0;
}
span.lav-components-text-size-body2 {
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 21px;
  line-height: 21px; /* 150% */
  margin: -5.5px 0;
}
span.lav-components-text-size-body3 {
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 24px;
  line-height: 24px; /* 150% */
  margin: -6px 0;
}
span.lav-components-text-size-subtext1 {
  font-size: 12px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 18px;
  margin: -5px 0;
  line-height: 18px; /* 150% */
  font-weight: 500;
  letter-spacing: normal;
}
span.lav-components-text-size-subtext2 {
  font-size: 14px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 21px;
  margin: -6px 0;
  line-height: 21px; /* 150% */
}
span.lav-components-text-size-subtext3 {
  font-size: 16px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 24px;
  margin: -6.5px 0;
  line-height: 24px; /* 150% */
}
span.lav-components-text-size-subtext4 {
  font-size: 20px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 30px;
  margin: -5.5px 0;
  line-height: 24px; /* 120% */
}
span.lav-components-text-size-subtext5 {
  font-size: 24px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 36px;
  margin: -6px 0;
  line-height: 28px; /* 116.667% */
}
span.lav-components-text-size-header1 {
  font-size: 36px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 54px;
  margin: -12.5px 0;
  line-height: 48px; /* 133.333% */
}
span.lav-components-text-size-header2 {
  font-size: 40px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 60px;
  margin: -15px 0;
  line-height: 56px; /* 140% */
}
span.lav-components-text-size-header3 {
  font-size: 48px;
  font-family: "Bricolage Grotesque";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 72px;
  margin: -20px 0;
  line-height: 72px; /* 150% */
}

span.ant-tag.lav-components-tag {
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 21px;
}
span.ant-tag.lav-components-tag.lav-components-tag-size-small {
  padding: 0 6px;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
}
span.ant-tag.lav-components-tag.lav-components-tag-size-medium {
  padding: 0 8px;
  height: 22px;
  line-height: 22px;
  font-size: 12px;
}
span.ant-tag.lav-components-tag.lav-components-tag-size-large {
  padding: 0 10px;
  height: 26px;
  line-height: 26px;
  font-size: 14px;
}
span.ant-tag.lav-components-tag-disabled-true {
  background-color: #f8f7f8;
  border: 2px solid #ececee;
  color: #b4b1bc;
  border-width: 1px;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-gray.lav-components-tag-variant-base {
  background-color: #ececee;
  color: #140c22;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-gray.lav-components-tag-variant-1 {
  background-color: #e1e0e4;
  color: #140c22;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-gray.lav-components-tag-variant-2 {
  background-color: #d6d4da;
  color: #140c22;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-gray.lav-components-tag-variant-3 {
  background-color: #cbc8d0;
  color: #140c22;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-gray.lav-components-tag-variant-4 {
  background-color: #b4b1bc;
  color: #140c22;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-gray.lav-components-tag-variant-5 {
  background-color: #858092;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-gray.lav-components-tag-variant-6 {
  background-color: #585462;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-gray.lav-components-tag-variant-7 {
  background-color: #423f4a;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-gray.lav-components-tag-variant-8 {
  background-color: #140c22;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-gray.lav-components-tag-variant-9 {
  background-color: #ececee;
  color: #140c22;
  border: 1px solid #140c22;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-outline {
  background-color: #ececee;
  color: #140c22;
  border: 1px solid #140c22;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-dark {
  background-color: #230a54;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-lavender.lav-components-tag-variant-base {
  background-color: #ece2f9;
  color: #42139f;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-lavender.lav-components-tag-variant-1 {
  background-color: #dbc5f6;
  color: #230a54;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-lavender.lav-components-tag-variant-2 {
  background-color: #cdaff3;
  color: #230a54;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-lavender.lav-components-tag-variant-3 {
  background-color: #bb96ef;
  color: #230a54;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-lavender.lav-components-tag-variant-4 {
  background-color: #9261ec;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-lavender.lav-components-tag-variant-5 {
  background-color: #6a2be9;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-lavender.lav-components-tag-variant-6 {
  background-color: #5318cc;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-lavender.lav-components-tag-variant-7 {
  background-color: #42139f;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-lavender.lav-components-tag-variant-8 {
  background-color: #230a54;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-lavender.lav-components-tag-variant-9 {
  background-color: #ece2f9;
  color: #42139f;
  border: 1px solid #6a2be9;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-pear.lav-components-tag-variant-base {
  background-color: #eaff97;
  color: #415f0c;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-pear.lav-components-tag-variant-1 {
  background-color: #eaff97;
  color: #415f0c;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-pear.lav-components-tag-variant-2 {
  background-color: #d7fb5b;
  color: #415f0c;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-pear.lav-components-tag-variant-3 {
  background-color: #caf344;
  color: #415f0c;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-pear.lav-components-tag-variant-4 {
  background-color: #afe222;
  color: #203207;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-pear.lav-components-tag-variant-5 {
  background-color: #89b918;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-pear.lav-components-tag-variant-6 {
  background-color: #638c12;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-pear.lav-components-tag-variant-7 {
  background-color: #415f0c;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-pear.lav-components-tag-variant-8 {
  background-color: #203207;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-pear.lav-components-tag-variant-9 {
  background-color: #f5ffc8;
  color: #415f0c;
  border: 1px solid #89b918;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-sapphire.lav-components-tag-variant-base {
  background-color: #d6edfa;
  color: #12458c;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-sapphire.lav-components-tag-variant-1 {
  background-color: #a9d7f4;
  color: #12458c;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-sapphire.lav-components-tag-variant-2 {
  background-color: #7cbfee;
  color: #12458c;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-sapphire.lav-components-tag-variant-3 {
  background-color: #439de7;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-sapphire.lav-components-tag-variant-4 {
  background-color: #2285e2;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-sapphire.lav-components-tag-variant-5 {
  background-color: #1a70cb;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-sapphire.lav-components-tag-variant-6 {
  background-color: #1861b9;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-sapphire.lav-components-tag-variant-7 {
  background-color: #12458c;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-sapphire.lav-components-tag-variant-8 {
  background-color: #0c2c5f;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-sapphire.lav-components-tag-variant-9 {
  background-color: #d6edfa;
  color: #12458c;
  border: 1px solid #1a70cb;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-marigold.lav-components-tag-variant-base {
  background-color: #fef2be;
  color: #b55708;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-marigold.lav-components-tag-variant-1 {
  background-color: #fde98a;
  color: #78380f;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-marigold.lav-components-tag-variant-2 {
  background-color: #fde98a;
  color: #78380f;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-marigold.lav-components-tag-variant-3 {
  background-color: #fdd74c;
  color: #78380f;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-marigold.lav-components-tag-variant-4 {
  background-color: #fcc72c;
  color: #78380f;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-marigold.lav-components-tag-variant-5 {
  background-color: #f6a40a;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-marigold.lav-components-tag-variant-6 {
  background-color: #da7c05;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-marigold.lav-components-tag-variant-7 {
  background-color: #b55708;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-marigold.lav-components-tag-variant-8 {
  background-color: #78380f;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-marigold.lav-components-tag-variant-9 {
  background-color: #fffae5;
  color: #b55708;
  border: 1px solid #f6a40a;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-peony.lav-components-tag-variant-base {
  background-color: #fde8f4;
  color: #880c35;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-peony.lav-components-tag-variant-1 {
  background-color: #fac2e1;
  color: #880c35;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-peony.lav-components-tag-variant-2 {
  background-color: #f79cc9;
  color: #630823;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-peony.lav-components-tag-variant-3 {
  background-color: #f377b1;
  color: #630823;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-peony.lav-components-tag-variant-4 {
  background-color: #ee448e;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-peony.lav-components-tag-variant-5 {
  background-color: #d31360;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-peony.lav-components-tag-variant-6 {
  background-color: #ad1049;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-peony.lav-components-tag-variant-7 {
  background-color: #880c35;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-peony.lav-components-tag-variant-8 {
  background-color: #630823;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-peony.lav-components-tag-variant-9 {
  background-color: #fde8f4;
  color: #d31360;
  border: 1px solid #d31360;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-tart.lav-components-tag-variant-base {
  background-color: #fadad6;
  color: #8c2312;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-tart.lav-components-tag-variant-1 {
  background-color: #f4aea9;
  color: #8c2312;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-tart.lav-components-tag-variant-2 {
  background-color: #ee807c;
  color: #5f1a0c;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-tart.lav-components-tag-variant-3 {
  background-color: #f05656;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-tart.lav-components-tag-variant-4 {
  background-color: #e22922;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-tart.lav-components-tag-variant-5 {
  background-color: #cb261a;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-tart.lav-components-tag-variant-6 {
  background-color: #b92818;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-tart.lav-components-tag-variant-7 {
  background-color: #8c2312;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-tart.lav-components-tag-variant-8 {
  background-color: #5f1a0c;
  color: #ffffff;
  border: none;
}
span.ant-tag.lav-components-tag:not(.lav-components-tag-disabled-true).lav-components-tag-color-tart.lav-components-tag-variant-9 {
  background-color: #fadad6;
  color: #8c2312;
  border: 1px solid #8c2312;
}