span[data-markjs="true"].lv-hl-underline {
  border-bottom: 2px solid #7253d5;
  /*background: #7253d51A;*/
  -webkit-animation: fadeinborder 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinborder 0.5s; /* Firefox < 16 */
  -ms-animation: fadeinborder 0.5s; /* Internet Explorer */
  -o-animation: fadeinborder 0.5s; /* Opera < 12.1 */
  animation: fadeinborder 0.5s;
}
.gmail_quote .lv-hl-underline {
  border: inherit;
}
blockquote .lv-hl-underline {
  border: inherit;
}
/*for outlook so subejct isnt cut off*/
._3SINzLq-TdkpyeK9NdanAg {
  overflow: visible !important;
}
._3zyakfMZGJ7herjQ-v7WqV {
  overflow: visible !important;
}
.lv-hl-underline-subject {
  /*box-sizing: border-box;*/
  border-bottom: 2px solid #7253d5 !important;
  position: absolute;
  z-index: 10;
  min-width: 100px;
  color: transparent;
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
}
@keyframes fadeinborder {
  from {
    border-bottom: 2px solid #9370db00;
  }
  to {
    border-bottom: 2px solid #7253d5;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeinborder {
  from {
    border-bottom: 2px solid #9370db00;
  }
  to {
    border-bottom: 2px solid #7253d5;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinborder {
  from {
    border-bottom: 2px solid #9370db00;
  }
  to {
    border-bottom: 2px solid #7253d5;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeinborder {
  from {
    border-bottom: 2px solid #9370db00;
  }
  to {
    border-bottom: 2px solid #7253d5;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeinborder {
  from {
    border-bottom: 2px solid #9370db00;
  }
  to {
    border-bottom: 2px solid #7253d5;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*.inboxsdk__compose_inlineReply{*/
/*  */
/*}*/
.aoI {
  /*for making position of icons relative*/
  position: relative !important;
  overflow-x: hidden;
}
.adB .aoI {
  overflow-x: visible !important;
}
._91LG5yQRzdMWoEcLoAg67,
.O4Jj0PuEee0zzeb6prTf,
#docking_InitVisiblePart_0 > div,
#docking_InitVisiblePart_1 > div {
  position: relative;
}
.aoI input[name="subjectbox"] {
  height: 20px;
  box-sizing: border-box;
}

.lv-hl-underline-subject.lv-edited {
  border-bottom: 2px dotted orange !important;
}
.aaZ {
  overflow: visible !important;
  /*for making tooltips visible*/
}
.lv-hl-underline.lv-edited {
  border-bottom: 2px dotted orange;
}
.lv-hr {
  border-top: 0;
  border-bottom: 1px solid rgba(201, 175, 246, 0.5);
  margin: 0 16px;
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.text-selectable {
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -o-user-select: text;
  user-select: text;
}

.lv-edit-email-box .unselectable {
  cursor: default;
}

.text-selectable .lv-bullet-point-result-subject,
.text-selectable .lv-edit-email-result-body {
  cursor: text;
}

.lv-hl-tooltip {
  position: absolute;
  background: white;
  /*border: 1px solid mediumpurple;*/
  z-index: 10012;
  font-family: Nunito, sans-serif;
  padding: 8px 16px;
  box-shadow: 0 0 50px rgba(45, 62, 80, 0.2);
  /*max-height: 200px;*/
  overflow-y: scroll;
  border-radius: 8px;
  -webkit-animation: fadein 0.1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.1s; /* Firefox < 16 */
  -ms-animation: fadein 0.1s; /* Internet Explorer */
  -o-animation: fadein 0.1s; /* Opera < 12.1 */
  animation: fadein 0.1s;
  outline: none !important;
}
.lv-hl-tooltip.recommendation {
  padding: 0;
  overflow-y: scroll;
}
/*
  @animesh - gpt-3 styling
  */
.like,
.dislike {
  display: inline-block;
  cursor: pointer;
  font-size: 0.5em;
  width: 13px;
  height: 13px;
  vertical-align: middle;
  margin-left: 4px;
}
.lv-light-underline {
  border-bottom: 1px solid lightgray;
}
.dislike:hover,
.like:hover {
  color: #7253d5;
  transition: all 0.2s ease-in-out;
  transform: scale(1.1);
}

/*.active {*/
/*  color: #7253d5;*/
/*}*/
.suggest-a-rec {
  background: linear-gradient(180deg, #7253d5 0%, #9e6ed4 100%);
  color: white;
  padding: 8px 16px;
  font-family: "Lato", sans-serif;
  font-size: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.suggest-a-rec:hover {
  text-decoration: underline;
}
.lv-hl-lg {
  width: 360px;
  height: 100px;
  object-fit: contain;
}
.lv-hl-rec {
  width: 360px;
}
.lv-hl-rec::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.lv-hl-tooltip.recommendation::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.lv-hl-rec .was-rec-help {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 0px;
  color: #ffffff;
  display: inline-block;
  margin-left: 65px;
  vertical-align: middle;
  /*display: none;!*turn off for demo*!*/
}
.lv-hl-rec .ignore-btn {
  color: white;
  cursor: pointer;
  font-size: 10px;
  position: absolute;
  right: 12px;
  top: 0;
}
.lv-hl-rec .add-to-library-btn {
  position: absolute;
  color: white;
  cursor: pointer;
  font-size: 10px;
  right: 56px;
  top: 0;
}
.lv-hl-rec p {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 11px;
  color: #7253d5;
  margin: 4px 0 2px;
  letter-spacing: 0.5px;
}
.lv-hl-rec .lv-rec-txt {
  font-family: "Karla", sans-serif;
  width: 90%;
  margin: 4px auto;
  padding: 6px 8px;
  color: #4e4e4e;
  font-weight: normal;
  font-size: 11px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  border-bottom-color: rgba(175, 143, 223, 0.6);
}
.lv-hl-rec .lv-rec-txt:hover {
  border: 1px solid rgba(175, 143, 223, 0.6);
  box-shadow: 0px 10px 60px rgba(226, 215, 234, 0.6);
}

.lv-hl-rec .lv-rec-old-txt {
  border: 1px solid #7253d5;
  font-family: Karla, sans-serif;
  width: 330px;
  margin: 4px auto;
  border-radius: 4px;
  padding: 8px;
  color: #4e4e4e;
  font-weight: normal;
  font-size: 11px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.lv-hl-rec-bottom-bar {
  height: 20px;
  background: #7253d5;
  border-radius: 0px 0px 4px 4px;
  width: 100%;
  position: relative;
  line-height: 19px;
}
.lv-hl-rec-bottom-bar > .lv-hl-rec-logo {
  width: 13px;
  height: 11px;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
}
.lv-hl-rec-bottom-bar > .lv-hl-rec-catie-logo {
  width: 68px;
  height: 14px;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 30px;
}
.lv-hl-rec-bottom-bar > p {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 0px;
  color: #ffffff;
  display: inline-block;
  margin-left: 65px;
}
.lv-hl-rec-line-break {
  margin: auto;
  border-bottom: 1.5px solid #f9f6fc;
  width: 95%;
}

.lv-hl-rec-long-words {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  /*line-height: 23px;*/
  color: #7253d5;
  /*text-indent: 10px;*/
  margin-left: 10px;
  margin-right: 10px;
  font-size: 11px;
  position: relative;
  align-items: center;
}
.lv-hl-rec-long-words > p {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  color: #7253d5;
  font-size: 11px;
}
.lv-hl-rec-long-words.lv-selected img {
  transform: rotate(180deg);
}
.lv-hl-rec-long-words.pointer:hover {
  color: #4c4289;
}

.lv-hl-rec-synonym-line-break {
  border: 0px;
  background-color: #f9f6fc;
  width: 86%;
  height: 3px;
  margin: auto;
  margin-top: 3px;
}

.lv-hl-synonym-list {
  height: auto;
  margin: 8px auto;
  margin-left: 10px;
  margin-right: 2px;
}
.lv-hl-synonym-list::-webkit-scrollbar {
  width: 8px;
}
.lv-hl-synonym-list::-webkit-scrollbar-track {
  background: white;
}
.lv-hl-synonym-list::-webkit-scrollbar-thumb {
  background: rgba(201, 175, 246, 0.5);
  border-radius: 8px;
  width: 8px;
  height: 20px;
}
.lv-font-black {
  color: #4e4e4e !important;
}
.lv-hl-rec-synonym {
  font-family: "Karla", sans-serif;
  font-size: 12px;
  padding: 5px;
  padding-top: 2px;
  color: #4e4e4e;
  margin: 2px;
  margin-right: 4px;
  box-sizing: border-box;
  cursor: pointer;
  max-width: 180px;
  max-height: 28px;
  border: 1.5px solid white;
  border-bottom-color: #af8fdf;
  border-radius: 5px;
  display: inline-block;
}
.lv-hl-rec-synonym:hover {
  box-shadow: 0px 10px 60px rgba(226, 215, 234, 0.7);
  /*border-radius: 5px;*/
  /*border: 1.5px solid #AF8FDF;*/
  border: 1.5px solid #af8fdf;
}

.lv-hl-rec-synonym > p {
  display: inline-block;
  text-align: left;
  font-size: 11px;
  line-height: 0px;
}
.lv-hl-rec-synonym > span {
  color: #7253d5;
  display: inline-block;
  text-align: right;
  font-size: 10px;
}

.lv-rec-box-hide {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 8px;
  top: 0px;
  margin-top: 6px;
  height: 10px;
  width: 10px;
  z-index: 1000;
}

.lv-rec-box-refresh {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 32px;
  top: 0px;
  margin-top: 0px;
  height: 10px;
  width: 10px;
  z-index: 1000;
}
.lv-rec-box-refresh > svg {
  transform: rotate(-30deg);
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  height: 10px;
  width: 10px;
}
.lv-rec-box-previous {
  transform: rotate(90deg);
  cursor: pointer;
  display: block;
  height: 10px;
  width: 10px;
  z-index: 1000;
  margin-left: 8px;
}
.lv-rec-box-previous.lv-inactive {
  filter: grayscale(100%);
  cursor: default;
  opacity: 0.5;
  display: none;
}
.lv-rec-box-next {
  transform: rotate(-90deg);
  cursor: pointer;
  display: block;
  height: 10px;
  width: 10px;
  z-index: 1000;
  margin-right: 8px;
}
.lv-rec-box-next.lv-inactive {
  filter: grayscale(100%);
  cursor: default;
  opacity: 0.5;
  display: none;
}

@keyframes ckw {
  0% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(330deg);
  }
}

.lv-hl-tooltip h3 {
  font-size: 12px;
}

.lv-hover-improvement-score {
  font-size: 10px;
  text-align: right;
  color: green;
}

.lv-synonym-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  color: #7253d5;
  border-radius: 4px;
  transition: all 50ms ease;
  font-size: 13px;
}

.lv-synonym-row:hover {
  background-color: #7253d5;
  color: white;
  box-shadow: 0 0 5px rgba(45, 62, 80, 0.2);
  cursor: pointer;
}

.lv-synonym-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  width: 100px;
  padding: 6px;
}

.lv-row {
  display: flex;
}
.lv-col {
  flex: 1 1;
}
.lv-col-fit {
  flex: 0 1;
}

.lv-similar-name-helpful {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90px;
  padding-left: 8px;
}
.misspelled-name-tooltip {
  font-size: 10px;
}
.misspelled-name-tooltip .lv-synonym-row {
  margin-top: 8px;
}
.lv-similar-name-helpful p {
  color: #4e4e4e;
  font-size: 12px;
}
.lv-similar-name-helpful img {
  vertical-align: bottom;
  margin-top: 6px;
  font-size: 0.7em;
}

.lv-short-compose-box {
  background: none;
  border-radius: 4px;
  width: 300px;
  height: fit-content;
  height: -moz-fit-content;
  box-shadow: rgba(46, 42, 49, 0.5) 0px 8px 20px;
  z-index: 10001; /*Specific z-index for salesloft modal*/
  font-family: "Karla", sans-serif;
  /*padding: 0 8px 4px 8px;*/
  position: absolute;
  bottom: 20px;
  left: 650px;
  font-size: 12px;
  text-align: left;
  color: darkred;
  overflow: hidden;
  display: none;
  filter: opacity(0.9);
  transition: filter 0.2s linear;
}
.lv-short-compose-box.lv-email-assistant-clicked {
  filter: opacity(1);
}
.lv-compose-tooltip {
  background: none;
  border-radius: 4px;
  width: fit-content;
  height: fit-content;
  box-shadow: rgba(46, 42, 49, 0.5) 0px 8px 20px;
  z-index: 10;
  position: absolute;
  /*top:0px;*/
  display: none;
  right: 0px;
}

.lv-email-verification-box {
  padding: 0;
  background: white;
  border-radius: 5px;
  box-shadow: rgba(46, 42, 49, 0.5) 0px 8px 20px;
  position: absolute;
  top: -23px;
  right: 30px;
  font-size: 12px;
  text-align: left;
  color: #4e4e4e;
  max-width: 500px;
  width: 303px;
  max-height: 300px;
  display: none;
  z-index: 1;
}
.lv-hl-rec .suggestion-title {
  font-size: 10px;
  color: #4e4e4e;
  padding: 0;
  margin: 4px 0 0 10px;
  text-transform: capitalize;
}
.lv-subject-analysis-box {
  padding: 0;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 50px rgba(45, 62, 80, 0.2);
  position: absolute;
  bottom: 55px;
  right: 0px;
  font-size: 10px;
  text-align: left;
  color: #4e4e4e;
  max-width: 270px;
  height: fit-content;
  height: -moz-fit-content;
  z-index: 10002;
  overflow: hidden;
  display: none;
}
.lv-hidden {
  display: none;
}
.lv-pointer {
  cursor: pointer;
}
/*name prob too generic*/
.intercom path {
  fill: white;
}
.intercom svg {
  vertical-align: middle;
  margin-left: 4px;
}
.intercom {
  color: white;
  font-size: 10px;
  vertical-align: middle;
  cursor: pointer;
  float: right;
  margin-right: 8px;
  margin-top: 4px;
  line-height: 12px;
  font-weight: bold;
}
.lv-custom-radio {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: white;
  border: 2px solid #5e53db;
  margin-right: 8px;
}
.lv-custom-radio > div {
  width: 4px;
  height: 4px;
  border-radius: 10px;
  margin: 2px auto auto;
}
.lv-custom-radio.lv-selected > div,
.lv-selected .lv-custom-radio > div {
  background: #5e53db;
}
.lv-capitalize {
  text-transform: capitalize;
}
.lv-bold {
  font-weight: 700 !important;
}
